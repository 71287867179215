import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import OFOSearch from '../OFOSearch';
import { LookupField } from '../../../widgets/LookupField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AETSetupForm = React.forwardRef((props, ref) => {

    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="AETSetup"
            dataURL='api/sims/aetsetupdetails/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController} org="ResolutionCircle"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="AETDetailsForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="AET Details" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AETProgrammeCode"
                                                key={tabValue}
                                                label="AET Programme Code"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={props.id}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="AETProgrammeDescription"
                                                key={tabValue}
                                                label="AET Programme Title"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                  
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
