import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../crm/CRM.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import CardLayout from "../../widgets/Card";
import withMenuPermission from "../../widgets/withMenuPermission";
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Document, Page, pdfjs } from 'react-pdf';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";

const pdfVersion = "2.6.347"
const pdfWorkerUrl = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfVersion}/pdf.worker.js`
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    errorMessageDisplay: {
        color: 'red'
    }
});

const utils = new ViewUtils();

const config = {

    postUrls: {
        documents: "api/sims/LearnershipSetupCRM/AddCRMDocumentBulkUpload"
    },

    docTypeUrls: {
        documents: "api/sims/LearnershipSetupcrm/GetLookupListLearnershipDocumentType"
    }
}

class LearnershipSetupCRMBulkUpload extends Component {

    state = {
        fileInfo: null,
        numPages: 0,
        lookupArray: null,
        documentPageInfo: [],
        message: null,
        messageStyle: null,
        savedSuccessfully: false
    }

    componentDidMount() {
        this.getlookup();
    }

    getlookup() {
        let url = config.docTypeUrls.documents;
        utils.invokeUrl(url, (response) => {
            this.setState({ lookupArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/LearnershipSetupCRM',
                state: {
                    LearnershipID: this.props.location.state.LearnershipID,
                    LearnershipName: this.props.location.state.LearnershipName,
                    SetCommunicationType: "2",
                    displayMissingDocTypes: this.state.savedSuccessfully
                }
            });
        }
    }

    handleChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result
                }
                this.setState({ fileInfo: fileInfo });
            }
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages });
    }

    handleArrayChange = (event, pageNumber) => {
        const values = [...this.state.documentPageInfo];

        const itemIndex = values.findIndex((item) => {
            return item.PageNumber === pageNumber;
        });

        const dropDownItem = this.state.lookupArray.find((item) => {
            return item.Id === event.target.value;
        });

        let pageInfo;
        if (itemIndex > -1) {
            pageInfo = { ...values[itemIndex] };
            pageInfo.LearnerDocumentTypeID = { Id: event.target.value, Description: dropDownItem.Description };
            values.splice(itemIndex, 1, pageInfo);
        }
        else {
            pageInfo = {
                PageNumber: pageNumber,
                LearnerDocumentTypeID: { Id: event.target.value, Description: dropDownItem.Description }
            };
            values.push(pageInfo);
        }

        this.setState({ documentPageInfo: values });
    }

    getArrayValue = (pageNumber) => {
        const item = this.state.documentPageInfo.find((item) => {
            return item.PageNumber === pageNumber;
        });

        if (item) {
            return item.LearnerDocumentTypeID.Id;
        } else {
            return 0;
        }
    }

    uploadDocument = () => {

        let saveObject = {
            EntityID: this.props.location.state.LearnershipID,
            FileInfo: this.state.fileInfo,
            DocumentPageInfo: this.state.documentPageInfo
        }

        let url = config.postUrls.documents;
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(saveObject)
            }
        )
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
        if (response.MessageType === "SUCCESS") {
            this.setState({ savedSuccessfully: true }, () => { this.handleToolbarButtonClick('close'); });
        }
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }


    render() {
        const { classes } = this.props;

        let pages = null;
        if (this.state.numPages > 0) {
            pages = [];
            for (let i = 0; i < this.state.numPages; i++) {
                pages.push(
                    <div style={{ border: '1px solid black', padding: '8px', marginTop: '4px', marginBottom: '4px' }}>
                        <Page pageNumber={i + 1} />
                        <p>{'Page ' + (i + 1)} of {this.state.numPages}</p>
                        <p>Document Type: </p>
                        <TextField className="w-30" id={"LearnerDocumentTypeID" + (i + 1)} value={this.getArrayValue((i + 1))} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleArrayChange(values, i + 1)}>
                            <MenuItem value={0} key={0} >  </MenuItem>
                            {this.state.lookupArray.map((lookupitem, index) => (

                                <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>

                            ))}
                        </TextField>
                    </div>
                );
            }
        }

        return (
            <CardLayout title="">
                <div style={{ width: "100%", height: "76%", padding: "8px", overflow: "hidden!important" }}>
                    <p className="breadcrumbs">
                        {`Learnership > ${this.props.location.state.LearnershipName} > CRM > Bulk Document Upload`}
                    </p>
                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={{
                            items: [
                                {
                                    type: "buttonGroup",
                                    buttons: [
                                        { id: 'close', label: '', tooltip: 'Back to Learnership CRM', icon: 'CloseIcon' },
                                    ]
                                }
                            ]
                        }} />

                    <input
                        accept="application/pdf"
                        className={classes.input}
                        id={
                            this.props.id ?
                                this.props.id
                                :
                                "contained-button-file"
                        }
                        multiple={this.props.multiple}
                        onChange={this.handleChange}
                        type="file"
                    />
                    <label htmlFor={
                        this.props.id ?
                            this.props.id
                            :
                            "contained-button-file"
                    }>
                        <Button variant="contained" component="span" className={classes.button} disabled={this.props.disabled}>
                            {
                                this.props.buttonLabel ?
                                    this.props.buttonLabel
                                    :
                                    "Upload File"
                            }
                        </Button>
                    </label>
                    {
                        <a href={this.props.downloadUrl}>
                            {this.state.fileInfo ? this.state.fileInfo.name : null}
                        </a>
                    }
                    {
                        this.state.fileInfo ?
                            <div style={{ maxWidth: "1000px" }}>
                                <Document
                                    style={{ width: "98%" }}
                                    file={this.state.fileInfo.base64}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                >
                                    {pages}
                                </Document>
                                <Button variant="outlined" size="small" id="btnSave" color="#01579B" onClick={this.uploadDocument}>Save</Button>&nbsp;
                                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                            </div>
                            : null
                    }
                </div>
            </CardLayout>
        );
    }
}
export default withRouter(withMenuPermission(withStyles(styles)(LearnershipSetupCRMBulkUpload)));
