import { trackPromise } from 'react-promise-tracker';
import * as actions from '../store/actions/auth';

export default class ViewUtils {
    constructor() {
    }

    SYSTEM_ERROR_MESSAGE = "A system error has occured. Please contact your system administrator";

    isNull(value) {
        return value === null || typeof value === 'undefined';
    }

    processToolbarConfig(viewMode, config) {
        let items = config.items;
        for (var i = 0; i < items.length; i++) {
            let type = items[i].type;
            if (type === 'buttonGroup') {
                let buttons = items[i].buttons;
                for (var j = 0; j < buttons.length; j++) {
                    let modes = buttons[j].modes;
                    if (!this.isNull(modes)) {
                        buttons[j].visible = modes.split(",").includes(viewMode);
                    }
                }
            }
        }

        // Force a new instance so that change event will be fired
        return JSON.parse(JSON.stringify(config));
    }

    getField(id, settings) {
        if (settings != null) {
            for (var i = 0; i < settings.fields.length; i++) {
                var field = settings.fields[i];
                if (field.name === id) {
                    return field;
                }
            }
        }

        return null;
    }

    isFieldRequired(id, settings, formId) {
        if (typeof settings !== 'undefined' && settings !== null) {
            let field = this.getField(id, settings);
            if (field != null) {
                return field.required;
            }
        }

        if (!this.isStringEmpty(formId)) {
            let fields = this.getFields(formId, 'validate');
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].id === id) {
                    return fields[i].required;
                }
            }
        }

        return false;
    }

    isFieldVisible(id, settings) {
        if (typeof settings !== 'undefined' && settings !== null) {
            let field = this.getField(id, settings);
            if (field != null) {
                return field.visible;
            }
        }

        return true;
    }

    isStringEmpty(val) {
        return typeof val === 'undefined' || val === null || val.trim().length === 0;
    }

    getFields(formId, className) {
        let inputFields = [];
        let form = document.getElementById(formId);
        if (!this.isNull(form)) {
            let inputDivs = form.getElementsByClassName(className);
            for (let i = 0; i < inputDivs.length; i++) {
                let inputs = inputDivs[i].getElementsByTagName("input");
                for (let j = 0; j < inputs.length; j++) {
                    inputFields.push(inputs[j]);
                }
            }
        }

        return inputFields;
    }

    validateField(id, required, values, currentValue) {
        return this.validateField(id, required, values, currentValue, null, null);
    }

    validateField(id, required, values, currentValue, message) {
        return this.validateField(id, required, values, currentValue, null, message);
    }

    validateField(id, required, values, currentValue, regex, message) {
        let result = {};
        result.valid = true;
        result.message = "";

        let value;
        if (currentValue !== null) {
            value = currentValue;
        } else {
            value = values[id];
        }

        let hasErrors = false;

        if (required && (this.isNull(value) || this.isStringEmpty(value.toString()))) {
            result.message = !this.isNull(message) ? message : "* Required";
            hasErrors = true;
        } else {
            result.message = "";
        }

        if (!hasErrors && !this.isNull(regex) && !this.isNull(value)) {
            hasErrors = this.isNull(value.toString().match(regex));
            if (hasErrors) {
                result.message = !this.isNull(message) ? message : "This field is invalid";
            }
        }

        result.valid = !hasErrors;
        return result;
    }

    doGetRequestJson(currentPage, curretRowsPerPage, currentSearchParameters, paged) {
        var request = {};
        request.pageSize = curretRowsPerPage;
        request.currentPage = currentPage;
        request.searchParameters = currentSearchParameters;
        request.paged = typeof paged === 'undefined' || paged === null || paged;

        return JSON.stringify(request);
    }

    getEntityViewTitle(queryParameterState, viewName, ...fields) {
        if (this.isNull(queryParameterState)) {
            return !this.isNull(viewName) ? viewName + " > Add" : "Add";
        } else {
            return viewName;
        }

        let props = "";
        for (let i = 0; i < fields.length; i++) {
            if (!this.isNull(fields[i])) {
                props += fields[i].trim();
                props += " ";
            }
        }

        props = props.trim();
        let prefix = this.isNull(viewName) ? "" : " > ";
        return (this.isNull(viewName) ? "" : viewName) + (props.length > 0 ? (prefix + props) : "");
    }

    setLegendColor(color) {
        let legends = document.getElementsByClassName("legend");
        for (let i = 0; i < legends.length; i++) {
            legends[i].style.color = color;
        }
    }

    setLegend(text) {
        if (!this.isNull(text)) {
            let legend = document.getElementById("globalLegend");
            if (legend) { legend.innerHTML = text; }
        }
    }

    showMessage = (viewRef, message, type) => {
        if (this.isNull(message)) {
            message = "";
        }

        //let messageType = type === "ERROR" ? "danger" : (this.isNull(type) ? "message" : type);
        let messageType = (this.isNull(type) ? "message" : type);
        viewRef.setState({ message: message, messageStyle: messageType });
    }

    saveForm(viewRef, formRef, url) {
        this.saveForm(viewRef, formRef, url, null, null, null);
    }

    saveForm(viewRef, formRef, url, successCallback, errorCallback) {
        this.saveForm(viewRef, formRef, url, successCallback, errorCallback, null);
    }



    updateForm(viewRef, formRef, url) {
        this.updateForm(viewRef, formRef, url, null, null, null);
    }

    updateForm(viewRef, formRef, url, successCallback, errorCallback) {
        this.updateForm(viewRef, formRef, url, successCallback, errorCallback, null);
    }



    offsetDate(date, yearOffset, monthOffset, dayOffset) {
        let year = date.getFullYear() + yearOffset;
        let day = date.getDate() + dayOffset;
        let month = date.getMonth() + monthOffset;

        return new Date(year, month, day);
    }


    autoSetDate(viewRef, formRef, newDate, yearOffset, monthOffset, dayOffset, autoFillDateName, refDateName) {
        let stateDate = viewRef.state[refDateName];
        let tempDate = stateDate
        if (this.isNull(stateDate) || stateDate.getTime() !== newDate.getTime()) {
            viewRef.setState({ ...viewRef.state, [refDateName]: newDate }, () => {
                if (!this.isNull(tempDate) && !this.isNull(viewRef.state.id)) {
                    let autoFillDate = this.offsetDate(newDate, yearOffset, monthOffset, dayOffset);
                    formRef.current.setValue(autoFillDateName, autoFillDate);
                }
                else if (this.isNull(viewRef.state.id)) {
                    let autoFillDate = this.offsetDate(newDate, yearOffset, monthOffset, dayOffset);
                    formRef.current.setValue(autoFillDateName, autoFillDate);
                }
            });
        }
    }

    CheckError(response) {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            throw Error(response.statusText);
        }
    }

    CheckHeader = (response) => {
        try {
            const store = window.globalAppRXStore;
            const auth = store.getState().auth;
            if (auth.interfaceVersionShouldBeChecked) {
                const interfaceVersionLatest = parseInt(response.headers.get('x-fe-version-header'));
                if (interfaceVersionLatest > this.GetInterfaceVersion(false)) {
                    store.dispatch(actions.interfaceVersionCheck(true));
                }
            }
        } catch (error) {
            console.log(error);
        }
        return response;
    }

    //API calls default to this method, with GET as the
    invokeUrl(url, successCallback, errorCallback) {
        this.invokeUrl(url, successCallback, errorCallback, null);
    }

    invokeUrl(url, successCallback, errorCallback, config) {
        let fetchConfig = this.isNull(config) ? {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        } : config;


        const ExpirationDate = new Date(localStorage.getItem("expirationDate"));
        const currentDate = new Date();
        var secondBetweenTwoDate = ((ExpirationDate - currentDate.getTime()) / 1000);

        if (secondBetweenTwoDate < 10) {
            const refreshFetchConfig = {
                method: 'POST'
            }
            trackPromise(
                fetch(`${process.env.PUBLIC_URL}/api/sims/security/SetRefreshToken`, refreshFetchConfig)
                    .then(this.CheckError)
                    .then((responseJson) => {
                        let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                        const expirationDate = new Date(new Date().getTime() + response.ExpiresInMin * 60000);
                        localStorage.setItem('token', response.AccessToken);
                        localStorage.setItem('expirationDate', expirationDate);
                        fetchConfig.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
                        trackPromise(
                            fetch(`${process.env.PUBLIC_URL}/` + url, fetchConfig)
                                .then(this.CheckHeader)
                                .then(this.CheckError)//.then(res => res.json())
                                .then((responseJson) => {
                                    let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                                    if (successCallback !== null) {
                                        successCallback(response);
                                    }
                                }).catch((e) => {
                                    if (errorCallback !== null) {
                                        errorCallback(e);
                                    }
                                }));

                    }).catch((e) => {
                        const store = window.globalAppRXStore;
                        const auth = store.getState().auth;
                        //Logout Logic
                        clearTimeout(auth.userLogoutTimer);
                        if (auth.loginRedirectToLoginPath !== '/ADlogin') {
                            store.dispatch(actions.authLogout());
                        }
                        else {
                            const adStore = window.globalAppADStore;
                            const isAuthenticated = adStore.getAllAccounts().length > 0;
                            if (isAuthenticated) {
                                adStore.logoutPopup().then((loginResponse) => {
                                    store.dispatch(actions.authLogout());
                                }).catch((error) => {
                                    console.log(error);
                                });
                            } else {
                                store.dispatch(actions.authLogout());
                            }
                        }
                    }));
        }
        else {
            trackPromise(
                fetch(`${process.env.PUBLIC_URL}/` + url, fetchConfig)
                    .then(this.CheckHeader)
                    .then(this.CheckError)//.then(res => res.json())
                    .then((responseJson) => {
                        let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                        if (successCallback !== null) {
                            successCallback(response);
                        }
                    }).catch((e) => {
                        if (errorCallback !== null) {
                            errorCallback(e);
                        }
                    }));
        }
    }

    //API calls default to this method, with DELETE as the default
    invokeUrlDelete(url, successCallback, errorCallback) {
        const config = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }
        this.invokeUrl(url, successCallback, errorCallback, config);
    }

    //API calls default to this method, with GET as the - external
    invokeUrlExternal(url, successCallback, errorCallback) {
        this.invokeUrl(url, successCallback, errorCallback, null);
    }

    invokeUrlExternal(url, successCallback, errorCallback, config) {
        let fetchConfig = this.isNull(config) ? {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        } : config;

        trackPromise(
            fetch(`${process.env.PUBLIC_URL}/` + url, fetchConfig)
                .then(this.CheckError)//.then(res => res.json())
                .then((responseJson) => {
                    let response = typeof responseJson === "object" ? responseJson : JSON.parse(responseJson);
                    if (successCallback !== null) {
                        successCallback(response);
                    }
                }).catch((e) => {
                    if (errorCallback !== null) {
                        errorCallback(e);
                    }
                }));
    }

    invokeUrlDeleteExternal(url, successCallback, errorCallback) {
        const config = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        }
        this.invokeUrlExternal(url, successCallback, errorCallback, config);
    }

    buildLinkURL(url) {
        return `${process.env.PUBLIC_URL}/` + url
    }

    saveForm(viewRef, formRef, url, successCallback, errorCallback, viewRoute, entityId) {
        viewRef.setState({ message: "", messageStyle: "message" });
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            if (!this.isNull(entityId)) {
                formValue.id = entityId;
            }
            else if (!this.isNull(viewRef.props.location.state) && !this.isNull(viewRef.props.location.state.selection)) {
                formValue.id = viewRef.props.location.state.selection;
            }

            let data = JSON.stringify(formValue);
            const fullUrl = url;
            this.invokeUrl(fullUrl, (response) => {
                this.showMessage(viewRef, response.Message, response.MessageType);
                if (successCallback !== null) {
                    successCallback(response);
                }

                if (!this.isNull(viewRoute) && !this.isNull(response.EntityId) && response.EntityId !== 0) {
                    viewRef.setState({ id: response.EntityId }, () => {
                        viewRef.props.history.push({
                            pathname: viewRoute,
                            state: { selection: viewRef.state.id }
                        })
                    })
                }
            }, (e) => {
                this.showMessage(viewRef, this.SYSTEM_ERROR_MESSAGE, "ERROR");
                if (errorCallback !== null) {
                    errorCallback(e);
                }
            },
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: data
                }
            );
        }
    }
    updateForm(viewRef, formRef, url, successCallback, errorCallback, viewRoute, entityId) {
        viewRef.setState({ message: "", messageStyle: "message" });
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            if (!this.isNull(entityId)) {
                formValue.id = entityId;
            }
            else if (!this.isNull(viewRef.props.location.state) && !this.isNull(viewRef.props.location.state.selection)) {
                formValue.id = viewRef.props.location.state.selection;
            }

            let data = JSON.stringify(formValue);
            const fullUrl = url;
            this.invokeUrl(fullUrl, (response) => {
                this.showMessage(viewRef, response.Message, response.MessageType);
                if (successCallback !== null) {
                    successCallback(response);
                }

                if (!this.isNull(viewRoute) && !this.isNull(response.EntityId) && response.EntityId !== 0) {
                    viewRef.setState({ id: response.EntityId }, () => {
                        viewRef.props.history.push({
                            pathname: viewRoute,
                            state: { selection: viewRef.state.id }
                        })
                    })
                }
            }, (e) => {
                this.showMessage(viewRef, this.SYSTEM_ERROR_MESSAGE, "ERROR");
                if (errorCallback !== null) {
                    errorCallback(e);
                }
            },
                {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: data
                }
            );
        }
    }
    saveFormExternal(viewRef, formRef, url, successCallback, errorCallback, viewRoute, entityId) {
        viewRef.setState({ message: "", messageStyle: "message" });
        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            if (!this.isNull(entityId)) {
                formValue.id = entityId;
            }
            else if (!this.isNull(viewRef.props.location.state) && !this.isNull(viewRef.props.location.state.selection)) {
                formValue.id = viewRef.props.location.state.selection;
            }

            let data = JSON.stringify(formValue);
            const fullUrl = url;
            this.invokeUrlExternal(fullUrl, (response) => {
                this.showMessage(viewRef, response.Message, response.MessageType);
                if (successCallback !== null) {
                    successCallback(response);
                }

                if (!this.isNull(viewRoute) && !this.isNull(response.EntityId) && response.EntityId !== 0) {
                    viewRef.setState({ id: response.EntityId }, () => {
                        viewRef.props.history.push({
                            pathname: viewRoute,
                            state: { selection: viewRef.state.id }
                        })
                    })
                }
            }, (e) => {
                this.showMessage(viewRef, this.SYSTEM_ERROR_MESSAGE, "ERROR");
                if (errorCallback !== null) {
                    errorCallback(e);
                }
            },
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: data
                }
            );
        }
    }
    invokeApiPut(data, url, successCallback, errorCallback, viewRoute, entityId) {

        const fullUrl = url;
        this.invokeUrl(fullUrl, (response) => {

            if (successCallback !== null) {
                successCallback(response);
            }

        }, (e) => {
            if (errorCallback !== null) {
                errorCallback(e);
            }
        },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: data
            }
        );

    }

    GetInterfaceVersion(runConsole) {
        var interfaceVersionValue = 999999;
        if (runConsole) {
            console.log("Current Interface Version: " + interfaceVersionValue);
        }
        return interfaceVersionValue;

    }
}
