import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import { ViewContainer } from "./../../../View.jsx";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import { AddEditTrancheForm } from './AddEditTrancheForm';



const TrancheRef = React.createRef();
const utils = new ViewUtils();
const toolbarRef = React.createRef();

class AddEditTranche extends Component {

    state = {
        /*Deciding on unique identifier for existing tranche details title*/
        TrancheDetails: {
            Description: "",
            Code: ""
        },
        messageStyle: "message",
        id: null,
        showTable: true,
        TrancheID: this.props.location.state.TrancheID,
        hideFields: this.props.location.state.hideFields,
        showAbledFields: null,
        showDisabledFields: null
    };

    toolbarConfig = {
        items: [

            {
                type: "buttonGroup",
                buttons: [
                    { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                ]
            },
            {
                type: "devider"
            },
            {

                type: "buttonGroup",
                buttons: [
                    { id: 'save', label: 'Submit', tooltip: 'Submit', icon: 'SaveIcon' },
                    { id: 'download', label: 'Download SLA Project to Implement Annexure', tooltip: 'Download SLA Project to Implement Annexure', icon: 'DownloadIcon' },
                    /*{ id: 'resubmit', label: 'Re-Submit', tooltip: 'Re-Submit', disabled: this.state.hideFields },*/
                    /*{ id: 'tranchedetail', label: 'Tranche Details', tooltip: 'Edit Tranche Details', disabled: this.state.hideFields },*/
                    { id: 'trancheprojectcost', label: 'Project Cost', tooltip: 'Edit Project Cost', disabled: this.state.hideFields },
                    { id: 'trancheelectiveus', label: 'Elective Unit Standards', tooltip: 'Link Elective Unit Standards', disabled: this.state.hideFields },
                    { id: 'learners', label: 'Learner', tooltip: 'Link Learner/s', disabled: this.state.hideFields },
                ]
            }

        ]
    };

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                if (!utils.isNull(this.state.ClientID) && this.state.ClientID != newValues.ClientID) {
                    newValues.ClientSDLNumber = null;
                }
                let showAbled = this.state.showAbledFields;
                let showDisabled = this.state.showDisabledFields;
                if (!utils.isNull(newValues.TrancheSetupDisabilityID)) {
                    if (newValues.TrancheSetupDisabilityID.Id == "1") {
                        showAbled = true;
                        showDisabled = false;
                        newValues.NumberOfLearnersDisabled = null
                        newValues.NumberOfLearnersRemainingDisabled = null
                    } else if (newValues.TrancheSetupDisabilityID.Id == "2") {
                        showAbled = false;
                        showDisabled = true;
                        newValues.NumberOfLearners = null
                        newValues.NumberOfLearnersRemaining = null
                    }
                    else if (newValues.TrancheSetupDisabilityID.Id == "3") {
                        showAbled = true;
                        showDisabled = true;
                    }
                }
                this.setState({
                    model: newValues,
                    ClientID: newValues.ClientID,
                    TrancheSetupDisabilityID: newValues.TrancheSetupDisabilityID,
                    showAbledFields: showAbled,
                    showDisabledFields: showDisabled
                });
            }
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("download" === buttonId) {
            this.DownloadSLAProjectAnnexure();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("resubmit" === buttonId) {
            this.resubmit();
        } else if ("tranchedetail" === buttonId) {
            this.props.history.push({
                pathname: '/TrancheDetails',
                state: {
                    GrantID: this.state.TrancheID
                }
            })
        }
        else if ("trancheprojectcost" === buttonId) {
            this.props.history.push({
                pathname: '/TrancheProjectCost',
                state: {
                    selection: this.state.TrancheID,
                    ProjectNumber: this.state.model.ProjectNumber
                }
            })
        }
        else if ("trancheelectiveus" === buttonId) {
            this.props.history.push({
                pathname: '/TrancheElectiveUS',
                state: {
                    selection: this.state.TrancheID,
                    ProjectNumber: this.state.model.ProjectNumber
                }
            })
        }
        else if ("learners" === buttonId) {
            this.props.history.push({
                pathname: '/TrancheLearners',
                state: {
                    selection: this.state.TrancheID,
                    ProjectNumber: this.state.model.ProjectNumber
                }
            })
        }
    }

    successCallback(response) {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    TrancheID: response.EntityId
                })
            });
            /*toolbarRef.current.setButtonDisabled("resubmit", false);*/
            toolbarRef.current.setButtonDisabled("download", false);
            toolbarRef.current.setButtonDisabled("tranchedetail", false);
            toolbarRef.current.setButtonDisabled("trancheprojectcost", false);
            toolbarRef.current.setButtonDisabled("trancheelectiveus", false);
            toolbarRef.current.setButtonDisabled("learners", false);
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    save() {
        let formValue = TrancheRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {

            utils.invokeUrl("api/sims/GrantSetup/grantSetupDetailsSubmit", (response) => {
                utils.showMessage(this, response.Message, response.MessageType);
                this.successCallback(response)
            },
                null,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formValue)
                }
            );
        }
    }

    resubmit() {
        let formValue = TrancheRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let input = JSON.stringify(formValue);
            let trancheData = {};
            trancheData.data = input;

            utils.invokeUrl("api/sims/GrantSetup/GrantSetupDetailsReSubmit", (response) => {
                utils.showMessage(this, response.Message, response.MessageType);
                this.successCallback(response)
            },
                null,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(trancheData)
                }
            );
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/GrantSetupSearch'
        });
    }

    DownloadSLAProjectAnnexure = () => {
        let url = "api/sims/GrantSetup/DownloadSLAProjectAnnexure?ID=" + this.state.TrancheID;
        utils.invokeUrl(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Project Setup Details"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    ref={toolbarRef}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "80vh" }}>
                                    {this.state.showTable === true && (
                                        <AddEditTrancheForm ref={TrancheRef}
                                            viewRef={this}
                                            id={this.state.TrancheID}
                                            hideFields={this.state.hideFields}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            data={this.state.model}
                                            showAbledFields={this.state.showAbledFields}
                                            showDisabledFields={this.state.showDisabledFields}
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddEditTranche);
