import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Provider.css";
import { ProviderForm } from './ProviderForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";



const providerRef = React.createRef();
const utils = new ViewUtils();

const toolbarRef = React.createRef();
class Provider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ProviderDetails: {
                LegalName: "",
                SDLNumber: "",
                AccreditationCheck: false
            },
            RequiredFields: null,
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },

                {
                    type: "devider"
                },

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'contacts', label: 'Contacts', tooltip: '', disabled: utils.isNull(this.state.id) },
                        { id: 'users', label: 'Users', tooltip: '', disabled: utils.isNull(this.state.id) },
                        {
                            contextMenu: [
                                { id: "unitstandard", description: "Unit Standard", disabled: utils.isNull(this.state.id) },
                                { id: "qualification", description: "Qualification", disabled: utils.isNull(this.state.id) },
                                { id: "skillsProgramme", description: "Skills Programmes", disabled: utils.isNull(this.state.id) },
                                { id: "learnerships", description: "Learnerships", disabled: utils.isNull(this.state.id) },
                                { id: "bursary", description: "Bursaries", disabled: utils.isNull(this.state.id) }

                            ],
                            id: 'programs', label: 'Programmes', disabled: utils.isNull(this.state.id)
                        },
                        { id: 'learners', label: 'Learners', tooltip: '', disabled: utils.isNull(this.state.id) },
                        { id: 'assesors', label: 'Assessor', tooltip: '', disabled: utils.isNull(this.state.id) },
                        { id: 'moderator', label: 'Moderator', tooltip: '', disabled: utils.isNull(this.state.id) },
                        { id: 'crm', label: 'CRM', tooltip: '', disabled: utils.isNull(this.state.id) }

                    ]
                }
            ]
        }
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {

            if (!utils.isNull(values.ProviderAccreditationStatusID) && !utils.isNull(values.AccreditationStartDate)) {
                if (!utils.isNull(values.AccreditationStartDate)) {
                    //Remember to change this from checking using Id as this can change in the database
                    if (values.ProviderAccreditationStatusID.Id === "2") {
                        utils.autoSetDate(this, providerRef, values.AccreditationStartDate, 2, 0, 1, "AccreditationEndDate", "AccreditationStartDate");
                    }
                    else if (values.ProviderAccreditationStatusID.Description === "Accredited") {
                        utils.autoSetDate(this, providerRef, values.AccreditationStartDate, 5, 0, 1, "AccreditationEndDate", "AccreditationStartDate");
                    }
                }

                if (values.ProviderAccreditationStatusID.Description === "Full Accreditation") {
                    let requiredFields = {
                        DHETRegistrationStartDate: "DHETRegistrationStartDate",
                        DHETRegistrationEndDate: "DHETRegistrationEndDate",
                        DHETRegistrationNumber: "DHETRegistrationNumber"
                    };

                    this.setState({ RequiredFields: requiredFields })
                }
                else {
                    this.setState({ RequiredFields: null });
                }
            }
            this.setState({ ProviderDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("close" === buttonId) {
            this.closeView();
        }
        else if ("contacts" === buttonId) {
            this.props.history.push({
                pathname: '/providerContacts',
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            });
        }
        else if ("users" === buttonId) {
            this.props.history.push({
                pathname: '/providerUsers',
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("siteVisits" === buttonId) {

            this.props.history.push({
                pathname: '/providerSiteVisits',
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        } else if ("learners" === buttonId) {
            this.props.history.push({
                pathname: '/providerLearner',
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: '/providerCRM',
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("assesors" === buttonId) {
            this.props.history.push({
                pathname: "/providerAssessorSearch",
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("moderator" === buttonId) {
            this.props.history.push({
                pathname: "/providerModeratorSearch",
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("accreditationHistory" === buttonId) {
            this.props.history.push({
                pathname: "/providerAccreditationHistory",
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("monitoringAndEvaluation" === buttonId) {
            this.props.history.push({
                pathname: "/providerMonitoringAndEvaluation",
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
    }

    handleToolbarButtonMenuClick(menu) {

        if ("skillsProgramme" === menu.id) {
            this.props.history.push({
                pathname: "/providerSkillsProgrammeSearch",
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        if ("bursary" === menu.id) {
            this.props.history.push({
                pathname: "/providerBursarySearch",
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("learnerships" === menu.id) {
            this.props.history.push({
                pathname: "/providerLearnershipSearch",
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("trades" === menu.id) {
            this.props.history.push({
                pathname: "/providerTrade",
                state: {
                    selection: this.state.id,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        } else if ("unitstandard" === menu.id) {
            this.props.history.push({
                pathname: "/providerUnitStandardSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
        else if ("qualification" === menu.id) {
            this.props.history.push({
                pathname: "/providerQualificationsSearch",
                state: {
                    selection: this.props.location.state.selection,
                    providerName: this.state.ProviderDetails.LegalName,
                    isAccredited: this.state.ProviderDetails.AccreditationCheck,
                    providerSDLNumber: this.state.ProviderDetails.SDLNumber
                }
            })
        }
    }

    successCallback = () => {

        this.setState({ message: "Provider updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = providerRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let isAdd = true;
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }
            let url = "api/sims/providerdetails/UpdateProvider";
            if (!isAdd) {
                utils.invokeUrl(url, (response) => {
                    this.successCallback(response)
                    this.getProviderDetails(response, isAdd, formValue)
                }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formValue)
                });
            } else {
                url = "api/sims/providerdetails/insertprovider";
                utils.invokeUrl(url, (response) => {
                    this.successCallback(response)
                    this.getProviderDetails(response, isAdd, formValue)
                }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formValue)
                });
            }
        }
    }

    getProviderDetails(response, isAdd, formValue) {

        if (response.MessageType === 'SUCCESS') {
            let dataURL = 'api/sims/providerdetails/getfind?id=';
            let ProviderId;
            if (isAdd) {
                dataURL = dataURL + response.EntityId;
                ProviderId = response.EntityId;
            } else {
                dataURL = dataURL + formValue.id;
                ProviderId = formValue.id;
            }
            utils.invokeUrl(dataURL, (response) => {
                console.clear();
                console.log(response);
                this.setState({ model: response, ConsultantID: null, id: ProviderId });
            },
                null
            )

            toolbarRef.current.setButtonDisabled("contacts", false);
            toolbarRef.current.setButtonDisabled("users", false);
            toolbarRef.current.setButtonDisabled("programs", false);
            toolbarRef.current.setButtonDisabled("learners", false);
            toolbarRef.current.setButtonDisabled("moderator", false);
            toolbarRef.current.setButtonDisabled("crm", false);
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/providerSearch',
            state: { selection: this.props.location.state.providerID }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">

                <p className="breadcrumbs">
                    {"Provider " + (this.state.ProviderDetails.LegalName !== null ? "> " + this.state.ProviderDetails.LegalName + (this.state.ProviderDetails.SDLNumber !== null ? " - " + this.state.ProviderDetails.SDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    ref={toolbarRef}
                                    title="Provider" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "55vh", maxHeight: "55vh" }}>
                                    <ProviderForm
                                        ref={providerRef}
                                        id={this.props.location.state.selection}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                        requiredFields={this.state.RequiredFields}
                                        editPermission={this.props.menuPermissionItem.Edit}
                                        data={this.state.model}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(Provider));

