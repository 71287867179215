import React from 'react';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';

const utils = new ViewUtils();
export const LearnerQCTOQualificationCertificationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="LearnerQCTOQualificationAssessment" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="LearnerQCTOQualifiationCert">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            <tr>

                                <td>
                                    <CustomTextField
                                        id="CertificateNumber"
                                        key={tabValue}
                                        label="Certificate Number"
                                        className="w-100"
                                        required
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td style={{ height: "55px" }}>
                                    <CustomDatePicker
                                        id="DateCertificateCreated"
                                        label="Certificate Date"
                                        className='w-100'
                                        required
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
