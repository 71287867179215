import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import ViewUtils from '../../ViewUtils';
import QualificationSetupSearchForm from '../program/QualificationSetupSearchForm';
import withMenuPermission from "../../widgets/withMenuPermission";
import FormDialog from "../FormDialog";
import { LearnerQCTOQualificationCertificationForm } from "./LearnerQCTOQualificationCertificationForm"
import { ViewContainer } from "./../../View.jsx";


const headCells = [
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'Qualification ID' },
    { id: 'ProgrammeTitle', numeric: false, disablePadding: true, label: 'Qualification Title' },
    { id: 'StudentNumber', numeric: false, disablePadding: true, label: 'Student Number' },
    { id: 'CommencementDate', numeric: false, disablePadding: true, label: 'Commencement Date' },
    { id: 'CompletionDate', numeric: false, disablePadding: true, label: 'Completion Date' },
    { id: 'ContractNumber', numeric: false, disablePadding: true, label: 'Contract Number' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
    { id: 'CertificateNumber', numeric: false, disablePadding: true, label: 'Certificate Number' },
    { id: 'DateCertificateCreated', numeric: false, disablePadding: true, label: 'Certificate Date' }
];



const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class LearnerQCTOQualificationAssessment extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = [
            { Name: "SAQAQualificationID", Value: "" },
            { Name: "SAQAQualificationTitle", Value: "" },
            { Name: "LearnerID", Value: this.props.location.state.learnerID }
        ]

        let model =
        {
            SAQAQualificationID: "",
            SAQAQualificationTitle: "",
            LearnerID: this.props.location.state.learnerID

        }

        this.state = !utils.isNull(this.props.location.state.learnerID) ?
            {
                formDialogOpen: false,
                showTable: true,
                model: model,
                keyUpSwitch: false,
                toolbarConfig: {
                    items: [
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                            ]
                        }
                        ,
                        {
                            type: "devider"
                        },
                        {
                            type: "buttonGroup",
                            buttons: [
                                { id: 'view', label: '', tooltip: 'View Modules', icon: 'ViewIcon', visible: this.props.menuPermissionItem.View },
                                { id: 'updateCert', label: '', tooltip: 'Update Certificate Information', icon: 'EditIcon', visible: this.props.menuPermissionItem.Edit }
                            ]
                        }
                    ]
                }
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };

        //TODO: Add url for Candidacy removal
        this.deleteUrl = ""
    }


    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.learnerID
                }
            });
        }
        else if (buttonId === "view") {
            this.props.history.push({
                pathname: '/ViewQCTOQualificationAssessment',
                state: {
                    LearnerID: this.props.location.state.learnerID,
                    QualificationID: this.state.selectedLearnerQualification[0].Id,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.menuPermissionItem
                }
            })
        } if ("updateCert" === buttonId) {
            this.setState({ formDialogOpen: true })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedLearnerQualification: selection });
            if (selection[0].ProgrammeStatus == "Achieved") {
                toolbarRef.current.setButtonDisabled("updateCert", false);
            }
            else {
                toolbarRef.current.setButtonDisabled("updateCert", true);
            }
        } else {
            this.setState({ selectedLearnerQualification: null })
            toolbarRef.current.setButtonDisabled("updateCert", true);
        }

        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
    }

    postAddHandler(isCancel, linked) {
        this.handleSelection(null);
        if (linked === true) {
            this.setState({
                showTable: false,
                formDialogOpen: false
            }, () => { this.setState({ showTable: true }) });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false
            })
        }
    }


    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (

            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > QCTO Qualification Assessments > ${this.props.location.state.learnerNames}`}
                </p>
                {this.state.showTable &&
                    <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/LearnerQCTOQualificationAssessment/getLearnerQCTOQualificationAssessments" : this.props.dataUrl}
                        entityViewPath='/ViewQCTOQualificationAssessment'
                        model={this.state.model} toolbarConfig={this.state.toolbarConfig}
                        searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                        descriptionProp="QualificationProgrammeDescription" mode={this.props.mode}
                        history={this.props.history} multiSelect={this.props.multiSelect}
                        hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                        keyUpSwitch={this.state.keyUpSwitch}
                        setSelection={(selection) => { this.handleSelection(selection) }}
                        toolbarRef={toolbarRef}
                        RunToolbarButtonPropOnly={true}
                    >
                        <QualificationSetupSearchForm
                            data={this.state.model}
                            onKeyUp={(e) => { this.onkeyup(e) }}
                            valueChangeHandler={(values) => {
                                if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                    this.setState({ model: values });
                                }
                            }}
                        />
                    </SearchView>
                }
                <FormDialog
                    open={this.state.formDialogOpen}
                    entityId={!utils.isNull(this.state.selectedLearnerQualification) ? this.state.selectedLearnerQualification[0].Id : null}
                    dialogTitle={"Update Certificate Detail"}
                    viewRef={this}
                    formRef={addFormRef}
                    saveUrl={"api/sims/LearnerQCTOQualificationAssessment/UpdateLearnerQCTOQualificationCertificateDetails"}
                    usePut={true}
                    closeHandler={(isCancelled, linked) => this.postAddHandler(isCancelled, linked)}
                >
                    <LearnerQCTOQualificationCertificationForm ref={addFormRef} />
                </FormDialog>

            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(LearnerQCTOQualificationAssessment));
