import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SubModulesTable from '../SubModulesTable';
import LinkDialog from "../LinkDialog";
import EnhancedToolbar from '../../widgets/Toolbar';
import SubModuleSetupSearch from './SubModuleSetupSearch';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Prompt from '../../widgets/Prompt';
import AlertItem from "../../widgets/AlertItem";

const utils = new ViewUtils();

const headCells = [
    { id: 'ModuleCode', numeric: false, disablePadding: true, label: 'QCTO Sub Module ID' },
    { id: 'ModuleTitle', numeric: false, disablePadding: true, label: 'QCTO Sub Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' }
];

const toolbarRef = React.createRef();
class ModuleSubModuleSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkDialogOpen: false,
            selectedLinkType: null,
            selectedLinkStandards: null,
            selectedSubModule: null,
            propmtOpen: false,
            promptTitle: "Unlink Sub Module From Module",
            promptContent: "Are you sure you want to unlink this sub module from the module?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "ModuleCode", Value: props.location.state.moduleId }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: 'Link', icon: 'LinkIcon', tooltip: 'Link the selected sub module to the module', width: "60px" },
                        { id: 'unlink', label: '', icon: 'LinkOffIcon', tooltip: 'Un-link the selected sub module from the module', disabled: utils.isNull(this.state.selectedSubModule) }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/Modulesetup',
                state: { selection: this.props.location.state.moduleId, id: this.props.location.state.moduleId }
            });
        } else if ("link" == buttonId) {
            this.setState({ linkDialogOpen: true });
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedSubModule: null,
                selectedLinkType: null,
                selectedLinkStandards: null,
                selectedSubModule: null,
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unlinkSubModule(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedSubModule}&moduleId=${this.props.location.state.moduleId}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedSubModule: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedSubModule: selection[0].Id,
                message: "",
                messageStyle: "",
            });
        } else {
            this.setState({
                selectedSubModule: null,
                message: "",
                messageStyle: "",
            });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer>
                <p className="legend">
                    {"Module > Sub Modules"}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    <SubModulesTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/ModuleSetupSubModule/GetSearch"
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.moduleTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Sub Modules to Module: ${this.props.location.state.moduleTitle}`}
                    linkName={"SubModules"} postUrl={"api/sims/ModuleSetupSubModule/linkSubModules"} usePost={true}
                    programmeId={this.props.location.state.moduleId} programmeType="Q"
                >
                    <SubModuleSetupSearch dataUrl="api/sims/ModuleSetupSubModule/GetSearchLinkableSubModules"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.moduleId} programmeType="Q"
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkSubModule(`api/sims/ModuleSetupSubModule/unlinkModuleSubModule`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ModuleSubModuleSearch)
);
