import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import "./TrancheSetupProjectCost.css";
import { TrancheSetupProjectCostForm } from './TrancheSetupProjectCostForm';
import ViewUtils from "../../../ViewUtils";
import { ViewContainer } from "../../../View";
import AlertItem from "../../../widgets/AlertItem";
import withMenuPermission from "../../../widgets/withMenuPermission";


const utils = new ViewUtils();
const ref = React.createRef();

class TrancheSetupProjectCost extends Component {
    state = {
        message: "",
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit }
                    ]
                }
            ]
        },
        showForm: true
    }



    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ TrancheSetupProjectCost: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: { TrancheID: this.props.location.state.selection }
            });
        }
    }

    successCallback = () => {

        this.setState({ message: "Project Costs updated successfully", messageStyle: "SUCCESS", showForm: false }, () => {
            this.setState({ showForm: true })
        });
    }

    save() {
        let formValue = ref.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let data = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/projectcost/UpdateTrancheSetupProjectCosts", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: data
            });
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Project Setup > Project Costs" + (this.props.location.state !== null ? " > " + this.props.location.state.ProjectNumber : "> Add")}
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="ProjectCosts" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showForm &&
                                        <TrancheSetupProjectCostForm viewRef={this} ref={ref} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} />
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(TrancheSetupProjectCost));

