import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";
import EmployerSearch from '../../employer/EmployerSearch';
import ProviderSearch from '../../provider/ProviderSearch';
import ProjectOwnerSearch from '../../projectowner/ProjectOwnerSearch';
import ConsultantSearch from '../../consultant/ConsultantSearch';
import FacilitatorSearch from '../../facilitator/FacilitatorSearch';
import ModeratorSearch from '../../moderator/ModeratorSearch';
import AssessorSearch from '../../assessor/AssessorSearch';
import WorkplaceSearch from '../../workplace/WorkplaceSearch';
/*import ProjectSearch from './ProjectSearch';*/
//import ContractSearch from './ContractSearch';
//import ActivitySearch from './ActivitySearch';
import *  as InterventionSearch from './InterventionIndex';
/*import { CustomCheckbox } from '../../../widgets/CustomCheckbox';*/
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import { makeStyles } from '@material-ui/core/styles';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const AddEditTrancheForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })

    function handleSelectionCallbackMode(value) {
        let objectReference = {};
        objectReference.Id = value[0].Id;
        objectReference.Description = value[0].Description;
        objectReference.toString = () => { return value[0].Description };
        return objectReference
    }

    function InterventionTypeChange(value, form) {
        if (typeof value !== 'undefined' && value !== null) {
            if (value.Description === "Learnership") {
                var type = 'LearnershipSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/LearnershipSearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "Skills Programme") {
                var type = 'SkillsProgramSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/SkillsProgrammeSearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "AET") {
                var type = 'AETSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/AETSearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "Bursary") {
                var type = 'BursarySetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/BursarySearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "Qualification") {
                var type = 'QualificationSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/QualificationSearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "Unit Standard") {
                var type = 'UnitStandardSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/UnitStandardSearch" autoFetchData={false} selection={props.selection} />
            }
        }
    }

    function LookupValidation(value) {
        if (value !== undefined && value !== null) {
            return false
        }
        else {
            return true
        }
    }

    function InterventionTypeRender(intervention, checkBValue) {
        if (intervention !== null && checkBValue !== null) {
            if (typeof intervention !== 'undefined' && intervention !== null) {
                if (intervention.Description === "Learnership" ||
                    intervention.Description === "Skills Programme" ||
                    intervention.Description === "AET" ||
                    intervention.Description === "Bursary" ||
                    intervention.Description === "Qualification" ||
                    intervention.Description === "Unit Standard") {
                    if (checkBValue == false) { return false }
                    else { return true }
                }
                else { return true; }
            }
            else { return true; }
        } else if (intervention !== null && (checkBValue == null || checkBValue == undefined)) {
            if (intervention.Description === "Learnership" ||
                intervention.Description === "Skills Programme" ||
                intervention.Description === "AET" ||
                intervention.Description === "Bursary" ||
                intervention.Description === "Qualification" ||
                intervention.Description === "Unit Standard") {
                return false;
            } else { return true; }
        }
        else { return true; }
    }

    function manageReqFlag(intervention, checkBValue) {
        if (intervention !== null && checkBValue !== null) {
            if (checkBValue == false) {
                if (intervention.Description === "Learnership" ||
                    intervention.Description === "Skills Programme" ||
                    intervention.Description === "AET" ||
                    intervention.Description === "Bursary" ||
                    intervention.Description === "Qualification" ||
                    intervention.Description === "Unit Standard") {
                    return true;
                } else { return false; }
            }
            else { return false; }
        } else if (intervention !== null && (checkBValue == null || checkBValue == undefined)) {
            if (intervention.Description === "Learnership" ||
                intervention.Description === "Skills Programme" ||
                intervention.Description === "AET" ||
                intervention.Description === "Bursary" ||
                intervention.Description === "Qualification" ||
                intervention.Description === "Unit Standard") {
                return true;
            } else { return false; }
        }
        else { return false; }
    }

    useEffect(() => {

    }, [])

    return (
        <EntityForm ref={ref} values={props.data} viewId="TrancheSetup" dataURL="api/sims/GrantSetup/GetTrancheSetupDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="addEditTrancheForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Setup Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="ClientID"
                                                label="Client"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl='api/sims/GrantSetup/ClientSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ClientID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=TrancheSetupPayroll"
                                                id="TrancheSetupPayrollID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Payroll"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                            {/*<CustomTextField*/}
                                            {/*    id="ClientSDLNumber"*/}
                                            {/*    key={tabValue}*/}
                                            {/*    label="Client SDL Number"*/}
                                            {/*    className="w-100"*/}
                                            {/*    disabled*/}
                                            {/*    InputProps={{*/}
                                            {/*        readOnly: true,*/}
                                            {/*    }}*/}
                                            {/*    form={form}*/}
                                            {/*/>*/}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {/*<SelectItem*/}
                                            {/*    dataUrl="api/sims/GrantSetup/GetLookupList?listId=Project"*/}
                                            {/*    id="ProjectNumber"*/}
                                            {/*    parentId={0}*/}
                                            {/*    required*/}
                                            {/*    key={tabValue}*/}
                                            {/*    label="Project"*/}
                                            {/*    className="{classes.select} w-100"*/}
                                            {/*    allowEmptyValue={true}*/}
                                            {/*    form={form}*/}
                                            {/*    required*/}
                                            {/*    InputProps={{*/}
                                            {/*        readOnly: props.disableFields*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                            <CustomTextField
                                                id="ProjectNumber"
                                                key={tabValue}
                                                label="Project ID"
                                                className="w-100"
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="ProjectOwnerID"
                                                label="Project Owner"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required
                                            >
                                                <ProjectOwnerSearch showTitle={true} mode='lookup' dataUrl='api/sims/GrantSetup/ProjectOwnerSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ProjectOwnerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                            {/*<LookupField*/}
                                            {/*    id="ContractNumber"*/}
                                            {/*    label="Contract"*/}
                                            {/*    className="w-100"*/}
                                            {/*    disabled={LookupValidation(form.values["ProjectNumber"])}*/}
                                            {/*    form={form}*/}
                                            {/*>*/}
                                            {/*    <ContractSearch showTitle={true} mode='lookup' ProjID={form.values["ProjectNumber"]}*/}
                                            {/*        onLookupValueChange={(value) => {*/}
                                            {/*            form.setLookupSelection("ContractNumber", value)*/}
                                            {/*        }} autoFetchData={false} selection={props.selection} />*/}
                                            {/*</LookupField>*/}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {/*<LookupField*/}
                                            {/*    id="ActivityNumber"*/}
                                            {/*    label="Activity"*/}
                                            {/*    className="w-100"*/}
                                            {/*    disabled={LookupValidation(form.values["ContractNumber"])}*/}
                                            {/*    form={form}*/}
                                            {/*>*/}
                                            {/*    <ActivitySearch showTitle={true} mode='lookup' ProjID={form.values["ProjectNumber"]}*/}
                                            {/*        ContractID={form.values["ContractNumber"]}*/}
                                            {/*        onLookupValueChange={(value) => {*/}
                                            {/*            form.setLookupSelection("ActivityNumber", handleSelectionCallbackMode(value))*/}
                                            {/*            form.values['VendorSDLNumber'] = value[0].VendorSDLNumber;*/}
                                            {/*            form.values['ActivityAgreementNumber'] = value[0].ActivityAgreementNumber;*/}
                                            {/*            form.values['NumberOfLearners'] = value[0].NumberOfLearners;*/}
                                            {/*            form.values['BudgetAmount'] = value[0].ContractBudgetAmount;*/}
                                            {/*        }} autoFetchData={false} selection={props.selection} />*/}
                                            {/*</LookupField>*/}
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="ProviderID"
                                                label="Provider"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required
                                            >
                                                <ProviderSearch showTitle={true} mode='lookup' dataUrl='api/sims/GrantSetup/ProviderSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ProviderID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                            {/*<CustomTextField*/}
                                            {/*    id="ActivityAgreementNumber"*/}
                                            {/*    key={tabValue}*/}
                                            {/*    label="Activity Agreement Number"*/}
                                            {/*    className="w-100"*/}
                                            {/*    disabled*/}
                                            {/*    InputProps={{*/}
                                            {/*        readOnly: true,*/}
                                            {/*    }}*/}
                                            {/*    form={form}*/}
                                            {/*/>*/}
                                            {/*<SelectItem*/}
                                            {/*    dataUrl="api/sims/GrantSetup/GetLookupList?listId=VendorType"*/}
                                            {/*    id="VendorTypeID"*/}
                                            {/*    parentId={0}*/}
                                            {/*    required*/}
                                            {/*    key={tabValue}*/}
                                            {/*    label="Vendor Type"*/}
                                            {/*    className="{classes.select} w-100"*/}
                                            {/*    allowEmptyValue={true}*/}
                                            {/*    form={form}*/}
                                            {/*    required*/}
                                            {/*    InputProps={{*/}
                                            {/*        readOnly: props.disableFields*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=DGType"
                                                id="ProgrammeType"
                                                required
                                                key={tabValue}
                                                label="Programme Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                            {/*<CustomCheckbox*/}
                                            {/*    id="AllProgrammes"*/}
                                            {/*    key={tabValue}*/}
                                            {/*    form={form}*/}
                                            {/*    inputProps={{*/}
                                            {/*        'aria-label': 'primary checkbox',*/}
                                            {/*    }}*/}
                                            {/*    label="Applicable to all programmes"*/}
                                            {/*/>*/}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                required={manageReqFlag(form.values["ProgrammeType"], form.values["AllProgrammes"])}
                                                id="InterventionID"
                                                label="Intervention"
                                                disabled={InterventionTypeRender(form.values["ProgrammeType"], form.values["AllProgrammes"])}
                                                className="w-100"
                                                form={form}
                                            >
                                                {InterventionTypeChange(form.values["ProgrammeType"], form)}
                                            </LookupField>
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Employment"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="ConsultantID"
                                                label="Consultant"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required
                                            >
                                                <ConsultantSearch showTitle={true} mode='lookup' dataUrl='api/sims/GrantSetup/ConsultantSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ConsultantID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                /*required*/
                                                key={tabValue}
                                                label="Time Period (Start/End)"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=TrancheSetupDisability"
                                                id="TrancheSetupDisabilityID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Disability"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="BudgetAmount"
                                                key={tabValue}
                                                label="Cost per Learner"
                                                className="w-100 "
                                                validationRegex={/^[0-9]+$/g}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    {props.showAbledFields ?
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="NumberOfLearners"
                                                    key={tabValue}
                                                    label="Total Learners"
                                                    className="w-100 "
                                                    validationRegex={/^[0-9]+$/g}
                                                    required
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="NumberOfLearnersRemaining"
                                                    key={tabValue}
                                                    label="Number Of Learners Remaining"
                                                    className="w-100 "
                                                    disabled
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr> : null
                                    }
                                    {props.showDisabledFields ?
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="NumberOfLearnersDisabled"
                                                    key={tabValue}
                                                    label="Total Learners (Disabled)"
                                                    className="w-100 "
                                                    validationRegex={/^[0-9]+$/g}
                                                    required
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="NumberOfLearnersRemainingDisabled"
                                                    key={tabValue}
                                                    label="Number Of Learners Remaining (Disabled)"
                                                    className="w-100 "
                                                    disabled
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr> : null
                                    }
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StartDate"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="EndDate"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="End Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=TrancheSetupTypeOfTraining"
                                                id="TrancheSetupTypeOfTrainingID"
                                                key={tabValue}
                                                label="Type Of Training"
                                                className="{classes.select} w-100"
                                                required
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=YesNo"
                                                id="ContractYesNoID"
                                                key={tabValue}
                                                label="Contract"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="DaysContactSession"
                                                key={tabValue}
                                                label="Days Contact Session"
                                                className="w-100 "
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Venue"
                                                key={tabValue}
                                                label="Venue"
                                                className="w-100 "
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=YesNo"
                                                id="YouthYesNoID"
                                                key={tabValue}
                                                label="Youth"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupListOrder?listId=TrancheSetupTimeframe&sortOrder=4"
                                                id="TrancheSetupTimeframeID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Timeframe(Months)"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=YesNo"
                                                id="EAPTargetsYesNoID"
                                                key={tabValue}
                                                label="EAP Targets"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="WorkplaceID"
                                                label="Workplace"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <WorkplaceSearch showTitle={true} mode='lookup' dataUrl='api/sims/GrantSetup/WorkplaceSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("WorkplaceID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="BillingPeriodDate"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Billing Period"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="ActualStartDate"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Actual Start Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="WorkplaceStartDate"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Workplace Start Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="FacilitatorID"
                                                label="Facilitator"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <FacilitatorSearch showTitle={true} mode='lookup' dataUrl='api/sims/GrantSetup/FacilitatorSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("FacilitatorID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="ModeratorID"
                                                label="Moderator"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <ModeratorSearch showTitle={true} mode='lookup' dataUrl='api/sims/GrantSetup/ModeratorSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ModeratorID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="AssessorID"
                                                label="Assessor"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <AssessorSearch showTitle={true} mode='lookup' dataUrl='api/sims/GrantSetup/AssessorSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("AssessorID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=YesNo"
                                                id="SLAYesNoID"
                                                key={tabValue}
                                                label="SLA"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=TrancheSetupTrainingVenue"
                                                id="TrancheSetupTrainingVenueID"
                                                key={tabValue}
                                                label="Training Venue"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomTextField
                                                id="Comments"
                                                key={tabValue}
                                                label="Comments"
                                                className="w-100"
                                                inputProps={{
                                                    maxLength: 2000
                                                }}
                                                multiline
                                                styles={styles()}
                                                form={form} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
