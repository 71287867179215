import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../../View.css";
import "./../../../Grid.css";
import "./../../../Form.css";
import "./../../../App.css";
import { ViewContainer } from "./../../../View.jsx";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';
import LinkDialog from "../../LinkDialog";
import LearnerSearch from '../../../etqa/learner/LearnerSearch';
import ViewUtils from '../../../ViewUtils';
import AlertItem from "../../../widgets/AlertItem";

export const learnerHeadCells = [
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'MiddleName', numeric: false, disablePadding: true, label: 'Middle Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: 'IDNo', numeric: false, disablePadding: true, label: 'IDNo' }
];

const toolbarRef = React.createRef();
const addFormRef = React.createRef();

const utils = new ViewUtils();

class GrantSetupLearnerSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                FirstName: "",
                MiddleName: "",
                Surname: "",
                IDNo: "",
                TrancheSetupID: this.props.location.state.selection
            },
            showTable: true,
            linkDialogOpen: false,
            updateDialogOpen: false,
            formDialogOpen: false,
            auditDialogOpen: false,
            selectedLearner: null,
            propmtOpen: false,
            promptTitle: "Unlink Learner From Project Setup",
            promptContent: "Are you sure you want to unlink this learner from the project setup?",
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "TrancheSetupID", Value: this.props.location.state.selection }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        /*{ id: 'view', label: '', tooltip: 'View the learner', disabled: utils.isNull(this.state.selectedLearner), icon: 'UpdateIcon' },*/
                        { id: 'link', label: '', tooltip: 'Link Learner/s to the Project Setup', icon: 'LinkIcon' }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/AddEditTranche',
                state: { TrancheID: this.props.location.state.selection }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        //else if ("view" === buttonId) {
        //    this.setState({ updateDialogOpen: true, message: "" })
        //}
    }

    successCallback(response) {

        if (response.MessageType === "WARNING") {
            let errResult = JSON.parse(response.Message);
            let message = "";
            errResult.map((item, index) => (
                message += item.Item1 + ' : ' + item.Item2 + '\n'
            ))
            this.setState({ message: message, messageStyle: response.MessageType });
        }
        else {
            this.setState({ message: response.Message, messageStyle: response.MessageType });
        }
    }

    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                /*searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),*/
                linkDialogOpen: false, selectedLearner: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    linkHandler(selection) {
        let data = {};
        data.ProgrammeId = this.props.location.state.selection;
        data.Learners = selection;
        let url = "api/sims/GrantSetupLearner/LinkTrancheSetupLearner";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedLearner: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        } else {
            this.setState({
                selectedLearner: null
            });
        }
        /*toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);*/
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Project Setup > Learners" + (this.props.location.state !== null ? " > " + this.props.location.state.ProjectNumber : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={learnerHeadCells}
                            dataUrl={`api/sims/GrantSetupLearner/getgrantsetuplearners`}
                            height="50vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.ProjectNumber}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Learner to Project Setup: ${this.props.location.state.ProjectNumber}`}
                    linkName={"Learners"} linkHandler={(selection) => this.linkHandler(selection)} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <LearnerSearch
                        dataUrl={"api/sims/GrantSetupLearner/GetSearchLinkableLearners"}
                        multiSelect={true} showTitle={false} customMessage={"No Learner with this search criteria have been found."}
                        programmeId={this.props.location.state.selection} programmeType="Q"
                        mode="lookup" allowClear={true}
                    />
                </LinkDialog>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(GrantSetupLearnerSearch)
);
