import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import ViewUtils from '../../ViewUtils';
import { AssessmentModuleHeaderForm } from './AssessmentModuleHeaderForm';

const headCells = [
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'Module Code' },
    { id: "ProgrammeTitle", numeric: false, disablePadding: true, label: "Module Title" },
    { id: "Credits", numeric: false, disablePadding: true, label: "Credits" },
    { id: "UnitStandardType", numeric: false, disablePadding: true, label: "Module Type" },
    { id: "Status", numeric: false, disablePadding: true, label: "Status" },
]


const utils = new ViewUtils();
const toolbarRef = React.createRef();
const learnerRef = React.createRef();

class ViewQCTOQualificationAssessment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                AssessmentID: null
            },
            selectedModule: null,
            selectedModuleCode: null,
            selectedModuleTitle: null,
            keyUpSwitch: false,
            messsageAlert: null,
            messageStyle: null,
            showTable: true,
            searchParameters: [{ Name: "AssessmentID", Value: this.props.location.state.QualificationID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'cancel', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View Sub Module', icon: 'ViewIcon', disabled: utils.isNull(this.state.selectedModule) },
                        { id: 'qaapproval', label: 'QA APPROVAL', tooltip: 'QA APPROVAL', disabled: true, visible: this.props.location.state.permissions.Edit }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("cancel" === buttonId) {
            this.props.history.push({
                pathname: '/LearnerQCTOQualificationAssessment',
                state: {
                    learnerID: this.props.location.state.LearnerID,
                    learnerNames: this.props.location.state.learnerNames
                }
            });
        } else if ("view" === buttonId) {
            this.props.history.push({
                pathname: '/ViewQCTOQualificationSubModuleAssessment',
                state: {
                    LearnerID: this.props.location.state.LearnerID,
                    learnerNames: this.props.location.state.learnerNames,
                    QualificationID: this.props.location.state.QualificationID,
                    ModuleID: this.state.selectedModule,
                    ModuleCode: this.state.selectedModuleCode,
                    ModuleTitle: this.state.selectedModuleTitle,
                    permissions: this.props.location.state.permissions
                }
            });
        } else if ("qaapproval" === buttonId) {
            this.qaApproval();

        }
    }

    GetQAPermission() {
        let url = "api/sims/LearnerQCTOQualificationAssessment/GetQCTOQAPermission?AssessmentID=" + this.props.location.state.QualificationID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "1") {
                toolbarRef.current.setButtonDisabled("qaapproval", true);
            }
            else {
                toolbarRef.current.setButtonDisabled("qaapproval", false);
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    componentDidMount() {
        this.GetQAPermission();
    }

    qaApproval() {
        let url = "api/sims/LearnerQCTOQualificationAssessment/ApproveQCTOAssessment?AssessmentID=" + this.props.location.state.QualificationID;
        utils.invokeUrl(url, (response) => {
            this.successCloseOffandQA(response)
            toolbarRef.current.setButtonDisabled("qaapproval", true);
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    successCloseOffandQA = (response) => {
        this.setState({
            message: response.Message,
            messageStyle: response.MessageType,
            showTable: false
        }, () => {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType,
                showTable: true
            })
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedModule: selection[0].Id, Message: "", selectedModuleCode: selection[0].ProgrammeCode, selectedModuleTitle: selection[0].ProgrammeTitle })
        } else {
            this.setState({ selectedModule: null, Message: "", selectedModuleCode: null, selectedModuleTitle: null })
        }

        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                this.setState({
                    model: newValues,
                    Message: ""
                });
                toolbarRef.current.setButtonDisabled("Cancel", true);
            }
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Update QCTO Qualification Module Assessment > ${this.props.location.state.learnerNames}`}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                {this.state.showTable === true && (
                    <AssessmentModuleHeaderForm
                        ref={learnerRef}
                        viewRef={this}
                        id={this.props.location.state.QualificationID}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                        controllerName={"LearnerQCTOQualificationAssessment"}
                        dataURL='api/sims/LearnerQCTOQualificationAssessment/GetLearnerQCTOQualificationAssessmentHeaderDetails' />
                )}
                <td>
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                </td>

                <div>
                    {this.state.showTable === true && (
                        <EnhancedTable
                            multiSelect={false}
                            headCells={headCells}
                            dataUrl="api/sims/LearnerQCTOQualificationAssessment/getLearnerQCTOQualificationModuleAssessmentDetails"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ViewQCTOQualificationAssessment)
);