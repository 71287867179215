import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Employer.css";
import { ViewContainer } from "./../../View.jsx";
import { EmployerForm } from './EmployerForm';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";
import { is } from "date-fns/esm/locale";


const formRef = React.createRef();
const utils = new ViewUtils();

const toolbarRef = React.createRef();
class Employer extends Component {



    constructor(props) {
        super(props);
        this.state = {
            employerDetails: {
                LegalName: "",
                SDLNumber: ""
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: this.props.menuPermissionItem.Edit },
                        { id: 'learners', label: 'LEARNERS', tooltip: 'Registered learners employed by this organisation', disabled: utils.isNull(this.state.id) },
                        { id: 'contacts', label: 'CONTACTS', tooltip: 'Contacts for this client', disabled: utils.isNull(this.state.id) },
                        { id: 'users', label: 'Users', tooltip: 'Users for this organisation', disabled: utils.isNull(this.state.id) },
                        { id: 'crm', label: 'CRM', tooltip: 'CRM', disabled: utils.isNull(this.state.id) },
                        { id: 'billingcontacts', label: 'BILLING CONTACTS', tooltip: 'Billing Contacts for this client', disabled: utils.isNull(this.state.id) },
                    ]
                }
            ]
        };
    }



    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            this.setState({
                employerDetails: {
                    LegalName: values.LegalName,
                    SDLNumber: values.SDLNumber
                }
            });
        }

        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                if (!utils.isNull(this.state.ConsultantID) && this.state.ConsultantID != newValues.ConsultantID) {
                    newValues.ConsultantNumber = null;
                    newValues.ConsultantEmail = null;
                }
                this.setState({
                    model: newValues,
                    ConsultantID: newValues.ConsultantID
                });
            }
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("learners" === buttonId) {
            this.props.history.push({
                pathname: '/employerLearners',
                state: { employerId: this.state.id, employerName: this.state.employerDetails.LegalName, employerSDLNo: this.state.employerDetails.SDLNumber }
            });
        } else if ("contacts" === buttonId) {
            this.props.history.push({
                pathname: '/employerContacts',
                state: { selection: this.state.id, employerName: this.state.employerDetails.LegalName, employerSDLNo: this.state.employerDetails.SDLNumber }
            });
        } else if ("users" === buttonId) {
            this.props.history.push({
                pathname: '/employerUsers',
                state: {
                    selection: this.state.id,
                    employerName: this.state.employerDetails.LegalName, employerSDLNo: this.state.employerDetails.SDLNumber
                }
            });
        } else if ("crm" === buttonId) {
            this.props.history.push({
                pathname: '/EmployerCRM',
                state: {
                    selection: this.state.id,
                    employerName: this.state.employerDetails.LegalName, employerSDLNo: this.state.employerDetails.SDLNumber
                }
            });
        } else if ("billingcontacts" === buttonId) {
            this.props.history.push({
                pathname: '/employerBillingContacts',
                state: { selection: this.state.id, employerName: this.state.employerDetails.LegalName, employerSDLNo: this.state.employerDetails.SDLNumber }
            });
        }
    }


    successCallback = () => {

        this.setState({ message: "Employer updated successfully", messageStyle: "SUCCESS" });
    }


    save() {

        let formValue = formRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            let isAdd = true;
            if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
                formValue.id = this.props.location.state.selection;
                isAdd = false;
            }
            formValue.Document = this.state.Document;
            const data = JSON.stringify(formValue);
            let url = "api/sims/employerdetails/updateemployer";
            if (!isAdd) {
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                    this.getEmployerDetails(response, isAdd, formValue)
                },
                    null,
                    {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            } else {
                url = "api/sims/employerdetails/insertemployer";
                utils.invokeUrl(url, (response) => {
                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response)
                    this.getEmployerDetails(response, isAdd, formValue)
                },
                    null,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: data
                    }
                )
            }
        }
    }


    getEmployerDetails(response, isAdd, formValue) {
        
        if (response.MessageType === 'SUCCESS') {
            let dataURL = 'api/sims/employerdetails/getfind?id=';
            let EmployerId;
            if (isAdd) {
                dataURL = dataURL + response.EntityId;
                EmployerId = response.EntityId;
            } else {
                dataURL = dataURL + formValue.id;
                EmployerId = formValue.id;
            }
            utils.invokeUrl(dataURL, (response) => {
                console.clear();
                console.log(response);
                this.setState({ model: response, ConsultantID: null, id: EmployerId });
            },
                null
            )
            toolbarRef.current.setButtonDisabled("learners", false);
            toolbarRef.current.setButtonDisabled("contacts", false);
            toolbarRef.current.setButtonDisabled("users", false);
            toolbarRef.current.setButtonDisabled("crm", false);
            toolbarRef.current.setButtonDisabled("billingcontacts", false);
        }
    }

    closeView() {
        this.props.history.push('/employers');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Client " + (this.state.employerDetails.LegalName !== null ? "> " + this.state.employerDetails.LegalName + (this.state.employerDetails.SDLNumber !== null ? " - " + this.state.employerDetails.SDLNumber : "") : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    ref={toolbarRef}
                                    title="Employer"
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer" style={{ maxHeight: "60vh" }}>
                                <EmployerForm ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} viewOnly={this.props.location.state.viewOnly}
                                    data={this.state.model}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(Employer));
