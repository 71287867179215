import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SubModulesTable from '../SubModulesTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import AlertItem from "../../widgets/AlertItem";


const utils = new ViewUtils();

const headCells = [
    { id: 'SubModuleCode', numeric: false, disablePadding: true, label: 'QCTO Sub Module ID' },
    { id: 'SubModuleTitle', numeric: false, disablePadding: true, label: 'QCTO Sub Module Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' }
];

const toolbarRef = React.createRef();

class ProviderQualificationModuleSMSearch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedSubModule: null,
            keyUpSwitch: false,
            message: "",
            messageStyle: "",
            searchParameters: [
                { Name: "ProviderQualificationID", Value: this.props.location.state.ProviderQualificationID },
                { Name: "ProviderID", Value: this.props.location.state.ProviderID },
                { Name: "ModuleID", Value: this.props.location.state.ModuleID }
            ]

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProviderQualificationModuleSearch',
                state: {
                    ProviderID: this.props.location.state.ProviderID,
                    providerName: this.props.location.state.providerName,
                    providerSDLNumber: this.props.location.state.providerSDLNumber,
                    ProviderQualificationID: this.props.location.state.ProviderQualificationID,
                    QualificationCode: this.props.location.state.QualificationCode,
                    QualificationTitle: this.props.location.state.QualificationTitle,
                }
            });
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedSubModule: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        } else {
            this.setState({
                selectedSubModule: null,
                message: "",
                messageStyle: ""
            });
        }
    }

    render() {
        return (
            <ViewContainer>
                <p className="breadcrumbs">
                    {"Provider > Qualification Modules > Sub Modules " + (this.props.location.state.providerName !== null ? "> " + this.props.location.state.providerName + (this.props.location.state.providerSDLNumber !== null ? " - " + this.props.location.state.providerSDLNumber : "") : "")
                        + (this.props.location.state.QualificationTitle !== null ? " > " + this.props.location.state.QualificationTitle + (this.props.location.state.QualificationCode !== null ? " - " + this.props.location.state.QualificationCode : "") : "")
                        + (this.props.location.state.ModuleTitle !== null ? " > " + this.props.location.state.ModuleTitle + (this.props.location.state.ModuleCode !== null ? " - " + this.props.location.state.ModuleCode : "") : "") }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    <SubModulesTable headCells={headCells} onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/providerqualification/GetProviderQualificationModuleSMSearch"
                        height={"50vh"}
                        searchParameters={this.state.searchParameters} paged={false} />
                </div>

            </ViewContainer>

        );
    }
}

export default withRouter(
    connect()(ProviderQualificationModuleSMSearch)
);
