import React, { Fragment } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { LookupField } from '../../widgets/LookupField';
import SchoolEMISSearch from '../SchoolEMISSearch';
import STATSSAAreaCodeSearch from '../STATSSAAreaCodeSearch';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { GPSCoordinatesAPI } from '../../widgets/GPSCoordinatesAPI';
import QualificationSetupSearch from '../program/QualificationSetupSearch';
import OFOSearch from '../program/OFOSearch';
import InstitutionSearch from '../program/InstitutionSearch';
import Button from '@material-ui/core/Button';
import EducationalHistoryDocuments from "./EducationalHistoryDocuments";
import LearnerDocumentDialog from "./LearnerDocumentDialog";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const LearnerForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [postalCodeValue, setPostalCodeValue] = React.useState(null);
    const [documentsDialogOpen, setdocumentsDialogOpen] = React.useState(false);
    const [EHdocumentsDialogOpen, setEHdocumentsDialogOpen] = React.useState(false);
    const [DocURL, setDocURL] = React.useState(null);
    const [showTable, setshowTable] = React.useState(true);
    const [ehDocURL, setehDocURL] = React.useState(null);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);

    const [showLeanerDialog, setShowLeanerDialog] = React.useState(false);
    const [showExperientialDialog, setShowExperientialDialog] = React.useState(false);


    const [leanerFileName, setLeanerFileName] = React.useState('');
    const [experientFileName, setExperientFileName] = React.useState('');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 3) {
            setDocURL("api/sims/learnerdetails/GetPostSchoolDocuments");
        }
        else {
            setDocURL(null);
            setehDocURL(null);
        }
    };

    const setDocumentDialogTabs = (type) => {
        //setShowLeanerDialog(false)
        setShowExperientialDialog(false)
        //if (type === 'showLeanerDialog') {
        //    setShowLeanerDialog(true)
        //} else
        if (type === 'showExperientialDialog') {
            setShowExperientialDialog(true)
        } else {

        }
    }
    const handleUsePhysicalAddressForPostal = form => event => {


        if (event.target.checked === true) {
            //form.values['PostalAddressLine1'] = form.values["PhysicalAddress1"];
            //form.values['PostalAddressLine2'] = form.values["PhysicalAddress2"];
            //form.values['PostalAddressLine3'] = form.values["PhysicalAddress3"];
            form.values['PostalCode'] = form.values["PhysicalCode"];
            ref.current.setValue('PostalAddressLine1', form.values["PhysicalAddress1"]);
            ref.current.setValue('PostalAddressLine2', form.values["PhysicalAddress2"]);
            ref.current.setValue('PostalAddressLine3', form.values["PhysicalAddress3"]);
            ref.current.setValue('PostalCode', form.values["PhysicalCode"]);
            setPostalCodeValue(form.values["PhysicalCode"]);
        }
        /*form.updateValue('UsePhysicalAsPostal', event.target.checked);*/
        ref.current.setValue('UsePhysicalAsPostal', event.target.checked);
    }
    const updateGPSAddress = (address, form) => {
        //form.values["GPSCoordinates"] = address.gpscoordinates.coordinate;
        //form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        //form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        //form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        ref.current.setValue('GPSCoordinates', address.gpscoordinates.coordinate);
        ref.current.setValue('PhysicalAddress1', address.streetnumberpar + ' ' + address.routepar);
        ref.current.setValue('PhysicalAddress2', address.sublocalitylevel1par);
        ref.current.setValue('PhysicalAddress3', address.localitypar);
        ref.current.setValue('PhysicalCode', address.postalcodepar);
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    function getDescription(values, form) {
        if (values !== undefined && values !== null) {
            if (values["SecondDescription"] !== undefined) {
                form.values["QualificationTitle"] = values["SecondDescription"];
            }
        }
    }

    function getOFODescription(values, form) {
        if (values !== undefined && values !== null) {
            if (values["SecondDescription"] !== undefined) {
                form.values["LinkedOFODescription"] = values["SecondDescription"];
            }
        }
    }

    const config = {
        moduleName: "Learner",

        dataUrls: {
            documents: null,
        },

        postUrls: {
            documents: "api/sims/learnerdetails/AddEducationHistoryDocument"
        }
    };


    const setDialogOpen = () => {
        setdocumentsDialogOpen(true);
    };

    const setehDialogOpen = () => {
        setEHdocumentsDialogOpen(true);
    };

    const setAddEducationHistoryFormVisible = () => {
        setshowTable(false);
        setShowLeanerDialog(true);
    };

    const handleCellSelect = (rowid, headCell) => {
        if (headCell.contentType === "document") {
            const link = document.createElement('a');
            link.href = '/api/sims/learnerdetails/getEvidenceofQualification?documentID=' + rowid;
            link.download = 'download';
            link.target = '_blank';
            link.click();
        }
    };

    const postUploadHandler = (save, cancel) => {
        //Successful upload
        if (save === true) {
            setshowTable(false);
            setdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
            setshowTable(true);
            setShowLeanerDialog(false);
        }
        //Close dialog
        else if (cancel === true) {
            setdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
            setshowTable(true);
            setShowLeanerDialog(false);
        }
        //Error on upload
        else {
            setdocumentsDialogOpen(false);
            setEHdocumentsDialogOpen(false);
        }
    };

    const checkEducationHistoryCheck = () => {
        return showLeanerDialog ? 'block' : 'none'
    }

    return (
        <EntityForm ref={ref} viewId="learner" values={props.data} dataURL='api/sims/learnerdetails/getfind'
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerForm">
                    <div className="row">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Title"
                                                id="TitleID"
                                                label="Title"
                                                className="w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="FirstName"
                                                label="First Name"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="MiddleName"
                                                label="Middle Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="Surname"
                                                label="Surname"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1', paddingTop: 10, minWidth: 50 }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs" variant="scrollable" scrollButtons="auto">
                                <Tab label="General Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null, fontSize: 12, fontWeight: 500, }} />
                                <Tab label="Contact Details" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null, fontSize: 12, fontWeight: 500 }} />
                                <Tab label="Address Details" {...a11yProps(2)} style={{ color: form.fieldParentErrors[2] === true ? "red" : null, fontSize: 12, fontWeight: 500 }} />
                                <Tab onClick={() => { setDocumentDialogTabs('showLeanerDialog') }} label="Educational History" {...a11yProps(3)} style={{ color: form.fieldParentErrors[3] === true ? "red" : null, fontSize: 12, fontWeight: 500 }} />
                                <Tab label="Post School Educational Details" {...a11yProps(4)} style={{ color: form.fieldParentErrors[4] === true ? "red" : null, fontSize: 12, fontWeight: 500 }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HasSouthAfricanID"
                                                id="HasSouthAfricanID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Does the Person have a South African ID Number?"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=AlternateIDType"
                                                id="AlternateIDTypeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Alternate ID Type"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="IDNo"
                                                key={tabValue}
                                                parentId={0}
                                                label="ID Number"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Verified"
                                                id="VerifiedID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Proof Of Life"
                                                className="w-100"
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Initials"
                                                key={tabValue}
                                                parentId={0}
                                                label="Initials"
                                                className="w-100"
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="DateofBirth"
                                                parentId={0}
                                                key={tabValue}
                                                label="Date of Birth"
                                                className='{classes.textField} w-100'
                                                required

                                                form={form}
                                                disabled={!props.editPermission}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Gender"
                                                id="GenderID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Gender"
                                                className="{classes.select} w-100"
                                                required={true}
                                                allowEmptyValue={true}
                                                InputProps={{
                                                    disabled: !props.editPermission
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Equity"
                                                id="EquityID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Race"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=YesNo"
                                                id="DisabilityYesNoID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Disability"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=HomeLanguage"
                                                id="HomeLanguageID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Home Language"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=CitizenResidentialStatus"
                                                id="CitizenResidentialStatusID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Citizen Residential Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=Nationality"
                                                id="NationalityID"
                                                required
                                                parentId={0}
                                                key={tabValue}
                                                label="Nationality"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=YesNo"
                                                id="YouthYesNoID"
                                                parentId={0}
                                                key={tabValue}
                                                label="Youth"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                            {/*<SelectItem*/}
                                            {/*    dataUrl="api/sims/learnerdetails/GetLookupList?listId=LearnerCategory"*/}
                                            {/*    id="LearnerCategoryID"*/}
                                            {/*    parentId={0}*/}
                                            {/*    key={tabValue}*/}
                                            {/*    label="Category"*/}
                                            {/*    className="{classes.select} w-100"*/}
                                            {/*    allowEmptyValue={true}*/}
                                            {/*    form={form}*/}
                                            {/*    disabled={!props.editPermission}*/}
                                            {/*    InputProps={{*/}
                                            {/*        readOnly: props.disableFields*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerdetails/GetLookupList?listId=LearnerHighestEducation"
                                                id="LearnerHighestEducationID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Highest Qualification"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="CellPhoneNumber"
                                                required
                                                parentId={1}
                                                key={tabValue}
                                                label="Cell Phone Number"
                                                validationRegex={"^0\\d{9}$|^2\\d{10}$"}
                                                validationMessage="Starting with 0 then number should be 10 digits long else starting with 2 it should be 11"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="TelephoneNumber"
                                                parentId={1}
                                                key={tabValue}
                                                label="Alternative Number"
                                                validationRegex={"^0\\d{9}$|^3\\d{10}$"}
                                                validationMessage="Starting with 0 then number should be 10 digits long else starting with 3 it should be 11"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="NextOfKin"
                                                required
                                                parentId={1}
                                                key={tabValue}
                                                label="Next Of Kin Name & Number"
                                                className="w-100"
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EMail"
                                                required
                                                parentId={1}
                                                key={tabValue}
                                                label="E Mail"
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled={!props.editPermission}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <table cellPadding="2" width="70%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomCheckbox
                                                parentId={2}
                                                id="UsePhysicalAsPostal"
                                                key={tabValue}
                                                form={form}
                                                onChange={handleUsePhysicalAddressForPostal}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Use Physical Address for Postal Address?"
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <GPSCoordinatesAPI
                                                parentId={2}
                                                id="Address"
                                                key={tabValue}
                                                className="w-100"
                                                label="Address"
                                                form={form}
                                                updateGPSAddress={updateGPSAddress}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="GPSCoordinates"
                                                key={tabValue}
                                                label="GPS Coordinates"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PhysicalAddress1"
                                                required
                                                key={tabValue}
                                                label="Physical Address 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PostalAddressLine1"
                                                key={tabValue}
                                                required
                                                label="Postal Address Line 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PhysicalAddress2"
                                                key={tabValue}
                                                required
                                                label="Physical Address 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PostalAddressLine2"
                                                required
                                                key={tabValue}
                                                label="Postal Address Line 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PhysicalAddress3"
                                                key={tabValue}
                                                label="Physical Address 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                parentId={2}
                                                id="PostalAddressLine3"
                                                key={tabValue}
                                                label="Postal Address Line 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <AddressCodeItem
                                                parentId={2}
                                                id="PhysicalCode"
                                                required={true}
                                                addressId="Physical"
                                                value={physicalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                                className="w-100"
                                                label="Physical Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form}
                                            />
                                        </td>

                                        <td>
                                            <AddressCodeItem
                                                parentId={2}
                                                id="PostalCode"
                                                required={true}
                                                addressId="Postal"
                                                value={postalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.PostalProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.PostalMunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.PostalDistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.PostalUrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.PostalSuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.PostalCityID : null}
                                                className="w-100"
                                                label="Postal Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={true}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <div style={{ display: checkEducationHistoryCheck() }}>
                                <table cellPadding="2" width="80%">
                                    <tbody>
                                        <tr>
                                            <td >
                                                <SelectItem
                                                    dataUrl="api/sims/learnerdetails/GetLookupList?listId=HighestEducationLevel"
                                                    id="HighestEducationLevelID"
                                                    parentId={3}
                                                    key={tabValue}
                                                    label="Highest Education Level"
                                                    className="{classes.select} w-100"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                    disabled={!props.editPermission}
                                                    InputProps={{
                                                        readOnly: props.disableFields
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="EducationTitle"
                                                    key={tabValue}
                                                    parentId={3}
                                                    label="Education Title / Description"
                                                    className="w-100"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                    disabled={!props.editPermission} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <LookupField
                                                    parentId={3}
                                                    id="InstitutionID"
                                                    label="Institution"
                                                    className="w-100"
                                                    form={form}
                                                    disabled={!props.editPermission}
                                                >
                                                    <InstitutionSearch showTitle={true} mode='lookup'
                                                        dataUrl='api/sims/learnerinternship/GetInstitutionSearch'
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("InstitutionID", value)
                                                        }} autoFetchData={true} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                        </tr>
                                        <br />
                                    </tbody>
                                </table>

                                <div>
                                    <LearnerDocumentDialog
                                        parentId={3}
                                        open={documentsDialogOpen}
                                        closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                        postUrl={config.postUrls.documents}
                                        EntityID={form.values["IDNo"]}
                                        form={form}
                                        buttonLabel={"Evidence of Qualification"}
                                        EducationTitle={form.values["EducationTitle"]}
                                        HighestEducation={form.values["HighestEducationLevelID"]}
                                        Institute={form.values["InstitutionID"]}
                                        fileName={leanerFileName}
                                        _setFilename={(name) => { setLeanerFileName(name) }}
                                    />
                                </div>
                            </div>

                            {/* <Button onClick={setDialogOpen} color="primary">
                                    Save Educational History Record
                                         </Button> */}
                            <p></p>
                            {showTable == true && (
                                <Fragment>
                                    <Button onClick={setAddEducationHistoryFormVisible} color="primary">
                                        Add Record
                                    </Button>
                                    <EducationalHistoryDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell)}
                                        documentsDataUrl={DocURL} moduleName={config.moduleName} selection={form.values["IDNo"]} />
                                </Fragment>
                            )}




                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="QualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                parentId={4}
                                                form={form}
                                                disabled={!props.editPermission}
                                                onChange={getDescription(form.values["QualificationID"], form)}
                                            >
                                                <QualificationSetupSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerdetails/LearnerQualificationSearch"
                                                    onLookupValueChange={(values) => form.setLookupSelection("QualificationID", values)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="QualificationTitle"
                                                key={tabValue}
                                                parentId={4}
                                                label="Qualification Name"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="DateAchieved"
                                                key={tabValue}
                                                label="Date Achieved"
                                                parentId={4}
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                parentId={4}
                                                id="OFOOccupationID"
                                                label="Linked OFO Code"
                                                className="w-100"
                                                onChange={getOFODescription(form.values["OFOOccupationID"], form)}
                                                form={form}
                                                disabled={!props.editPermission}
                                            >
                                                <OFOSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerinternship/getofooccupationsearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("OFOOccupationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="LinkedOFODescription"
                                                key={tabValue}
                                                parentId={4}
                                                label="Linked OFO Description"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
