import React from 'react';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const TrancheSetupProjectCostForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            if (!utils.isNull(props.valueChangeHandler)) {
                props.valueChangeHandler(values);
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="projectcost" values={props.data} org="saseta"
            dataURL='api/sims/projectcost/GetTrancheSetupProjectCostDetails'
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={(values) => { handleFormValueChange(values) }}>
            {form =>
                <form className={form.classes.container} id="TrancheSetupProjectCostsForm">
                    <FormTabContainer>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="Intervention"
                                            key={tabValue}
                                            label="Intervention"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="NumberOfLearners"
                                            key={tabValue}
                                            label="Number Of Learners"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="StipendPerLearner"
                                            validationRegex={"^[0-9]{0,6}(\.[0-9]{1,2})?$"}
                                            validationMessage="Only digits with 2 digits after the decimal"
                                            key={tabValue}
                                            required={true}
                                            label="Stipend Per Learner"
                                            className='{classes.textField} w-100'
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="TrainingCostPerLearner"
                                            validationRegex={"^[0-9]{0,6}(\.[0-9]{1,2})?$"}
                                            validationMessage="Only digits with 2 digits after the decimal"
                                            key={tabValue}
                                            required={true}
                                            label="Training Cost Per Learner"
                                            className='{classes.textField} w-100'
                                            form={form}
                                            disabled={!props.editPermission}
                                        />

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="TotalTrainingCost"
                                            key={tabValue}
                                            label="Total Training Cost"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="TotalStipendCost"
                                            key={tabValue}
                                            label="Total Stipend Cost"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="TotalProjectCost"
                                            key={tabValue}
                                            label="Total Project Cost"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="TotalVAT"
                                            key={tabValue}
                                            label="Total VAT"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="GrandProjectTotalIncludingVAT"
                                            key={tabValue}
                                            label="Grand Project Total Including VAT"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="NumberOfTranches"
                                            validationRegex={/^[0-9]+$/g}
                                            validationMessage="Only digits allowed"
                                            key={tabValue}
                                            required={true}
                                            label="Number of tranches"
                                            className='{classes.textField} w-100'
                                            form={form}
                                            disabled={!props.editPermission}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="TrancheAmount"
                                            key={tabValue}
                                            label="Tranche Amount"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            form={form}
                                            disabled={true}
                                        />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>

    );
})
