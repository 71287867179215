import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EmployerSearch from "./etqa/employer/EmployerSearch";
import Employer from "./etqa/employer/Employer";
import WorkplaceSearch from "./etqa/workplace/WorkplaceSearch";
import Workplace from "./etqa/workplace/Workplace";
import AssessorSearch from "./etqa/assessor/AssessorSearch";
import FacilitatorSearch from "./etqa/facilitator/FacilitatorSearch";
import ProjectOwnerSearch from "./etqa/projectowner/ProjectOwnerSearch";
import ConsultantSearch from "./etqa/consultant/ConsultantSearch";
import AuditTrial from "./etqa/assessor/AuditTrial";
import Assessor from "./etqa/assessor/Assessor";
import Facilitator from "./etqa/facilitator/Facilitator";
import ProjectOwner from "./etqa/projectowner/ProjectOwner";
import Consultant from "./etqa/consultant/Consultant";
import BDASearch from "./etqa/bda/BDASearch";
import PersonSearch from "./etqa/person/PersonSearch";
import LearnerSearch from "./etqa/learner/LearnerSearch";
import Learner from "./etqa/learner/Learner";
import LearnerCRM from "./etqa/learner/LearnerCRM";
import LearnerCRMBulkUpload from "./etqa/learner/LearnerCRMBulkUpload";
import Person from "./etqa/person/Person";
import LearnerSkillsProgrammeSearch from "./etqa/learner/LearnerSkillsProgram";
import LearnerBursarySearch from "./etqa/learner/LearnerBursary";
import LearnerUnitStandardSearch from "./etqa/learner/LearnerUnitStandard";
import LearnerCandidacySearch from "./etqa/learner/LearnerCandidacy";
import LearnerInternshipsSearch from "./etqa/learner/LearnerInternships";
import LearnerAETSearch from "./etqa/learner/LearnerAET";
import LearnerFETSearch from "./etqa/learner/LearnerFET";
import LearnerQualificationSearch from "./etqa/learner/LearnerQualification";
import LearnerQCTOQualificationSearch from "./etqa/learner/LearnerQCTOQualification";
import LearnerQualificationUSSearch from "./etqa/learner/LearnerQualificationUSSearch";
import LearnerTradeSearch from "./etqa/learner/LearnerTrade";
import LearnerLearnershipSearch from "./etqa/learner/LearnerLearnership";
import LearnerLearnershipAchievements from "./etqa/learner/LearnerLearnershipAchievement";
import LearnerUnitStandardAchievement from "./etqa/learner/LearnerUnitStandardAchievement";
import LearnerQualificationAchievement from "./etqa/learner/LearnerQualificationAchievement";
import LearnerSkillsProgrammeAchievement from "./etqa/learner/LearnerSkillsProgrammeAchievement";
import LearnerLearnershipAssessments from "./etqa/learner/LearnerLearnershipAssessment";
import ViewLearnershipAchievementUS from "./etqa/learner/ViewLearnershipAchievementUS";
import ViewSkillsProgrammeAchievementUS from "./etqa/learner/ViewSkillsProgrammeAchievementUS";
import ViewQualificationAchievementUS from "./etqa/learner/ViewQualificationAchievementUS";
import ViewLearnershipAssessment from "./etqa/learner/ViewLearnershipAssessment";
import ViewSkillsProgrammeAssessment from "./etqa/learner/ViewSkillsProgrammeAssessment";
import ViewBursaryAssessment from "./etqa/learner/ViewBursaryAssessment";
import ViewQualificationAssessment from "./etqa/learner/ViewQualificationAssessment";
import ViewQCTOQualificationAssessment from "./etqa/learner/ViewQCTOQualificationAssessment";
import ViewQCTOQualificationSubModuleAssessment from "./etqa/learner/ViewQCTOQualificationSubModuleAssessment";
import ViewUnitStandardAssessment from "./etqa/learner/ViewUnitStandardAssessment";
import LearnerUnitStandardAssessment from "./etqa/learner/LearnerUnitStandardAssessment";
import LearnerQualificationAssessment from "./etqa/learner/LearnerQualificationAssessment";
import LearnerQCTOQualificationAssessment from "./etqa/learner/LearnerQCTOQualificationAssessment";
import LearnerSkillsProgrammeAssessment from "./etqa/learner/LearnerSkillsProgrammeAssessment";
import LearnerBursaryAssessment from "./etqa/learner/LearnerBursaryAssessment";
import Moderator from "./etqa/moderator/Moderator";
import ModeratorSearch from "./etqa/moderator/ModeratorSearch";
import ModeratorSkillsProgramme from "./etqa/moderator/ModeratorSkillsProgramme";
import ModeratorBursary from "./etqa/moderator/ModeratorBursary";
import ModeratorQualification from "./etqa/moderator/ModeratorQualification";
import Moderatorlearnership from "./etqa/moderator/ModeratorLearnerships";
import ModeratorUnitStandards from "./etqa/moderator/ModeratorUnitStandards";
import ModeratorProviderSearch from "./etqa/moderator/ModeratorProviders";
import ModeratorRegistrationDetails from "./etqa/moderator/ModeratorRegistrationDetails";
import AssessorSkillsProgrammeSearch from "./etqa/assessor/AssessorSkillsProgramme";
import AssessorBursarySearch from "./etqa/assessor/AssessorBursary";
import FacilitatorSkillsProgrammeSearch from "./etqa/facilitator/FacilitatorSkillsProgramme";
import AssessorQualificationSearch from "./etqa/assessor/AssessorQualifications";
import FacilitatorQualificationSearch from "./etqa/facilitator/FacilitatorQualifications";
import FacilitatorBursarySearch from "./etqa/facilitator/FacilitatorBursarys";
import AssessorLearnershipSearch from "./etqa/assessor/AssessorLearnerships";
import FacilitatorLearnershipSearch from "./etqa/facilitator/FacilitatorLearnerships";
import AssessorLearnershipUSSearch from "./etqa/assessor/AssessorLearnershipUSSearch";
import FacilitatorLearnershipUSSearch from "./etqa/facilitator/FacilitatorLearnershipUSSearch";
import ModeratorRegistrationHistory from "./etqa/moderator/RegistrationHistory";
import ModeratorCRM from "./etqa/moderator/ModeratorCRM";
import UploadPerson from "./etqa/bulkupload/uploadPerson";
import UploadLearnership from "./etqa/bulkupload/uploadLearnership";
import UploadSkills from "./etqa/bulkupload/uploadSkills";
import UploadBursaries from "./etqa/bulkupload/uploadBursaries";
import UploadInternship from "./etqa/bulkupload/uploadInternship";
import UploadAET from "./etqa/bulkupload/uploadAET";
import UploadCandidacy from "./etqa/bulkupload/uploadCandidacy";
import UploadFET from "./etqa/bulkupload/uploadFET";
import UploadQualification from "./etqa/bulkupload/uploadQualification";
import UploadUnitStandard from "./etqa/bulkupload/uploadUnitStandard";
import UploadAchievement from "./etqa/bulkupload/uploadAchievement";
import AssessorRegistrationHistory from "./etqa/assessor/RegistrationHistory";
import FacilitatorRegistrationHistory from "./etqa/facilitator/RegistrationHistory";
import LearnerSkillsProgram from "./etqa/learner/LearnerSkillsProgram";
import AddLearnerSkillsProgram from "./etqa/learner/AddLearnerSkillsProgram";
import AddLearnerBursary from "./etqa/learner/AddLearnerBursary";
import AddLearnerInternships from "./etqa/learner/AddLearnerInternships";
import AddLearnerLearnership from "./etqa/learner/AddLearnerLearnership";
import AddLearnerQualification from "./etqa/learner/AddLearnerQualification";
import AddLearnerQCTOQualification from "./etqa/learner/AddLearnerQCTOQualification";
import AddLearnerUnitStandard from "./etqa/learner/AddLearnerUnitStandard";
import AddLearnerAET from "./etqa/learner/AddLearnerAET";
import AddLearnerFET from "./etqa/learner/AddLearnerFET";
import AddLearnerCandidacy from "./etqa/learner/AddLearnerCandidacy";
import AddLearnerTrade from "./etqa/learner/AddLearnerTrade";
import AssessorUnitStandards from "./etqa/assessor/AssessorUnitStandards";
import FacilitatorUnitStandards from "./etqa/facilitator/FacilitatorUnitStandards";
import AssessorProviderSearch from "./etqa/assessor/AssessorProvider";
import FacilitatorProviderSearch from "./etqa/facilitator/FacilitatorProvider";
import AssessorRegistrationDetails from "./etqa/assessor/AssessorRegistrationDetails";
import FacilitatorRegistrationDetails from "./etqa/facilitator/FacilitatorRegistrationDetails";
import AssessorCRM from "./etqa/assessor/AssessorCRM";
import FacilitatorCRM from "./etqa/facilitator/FacilitatorCRM";
import EmployerCRM from "./etqa/employer/EmployerCRM";

import OrganisationCRM from "./skills/organisation/OrganisationCRM";

import GrantSetupSearch from "./etqa/grants/grantsetup/GrantSetupSearch";
import AddEditTranche from "./etqa/grants/grantsetup/AddEditTranche";
import TrancheDetails from "./etqa/grants/grantsetup/TrancheDetails";
import GrantSetupElectiveUSSearch from "./etqa/grants/grantsetup/GrantSetupElectiveUSSearch";
import TrancheSetupProjectCost from "./etqa/grants/grantsetup/TrancheSetupProjectCost";
import TrancheSetupLearners from "./etqa/grants/grantsetup/GrantSetupLearnerSearch";
import ProcessingNav from "./etqa/grants/grantprocessing/ProcessingNav";
import GenerateClaimSearch from "./etqa/grants/grantprocessing/GenerateClaimSearch";
import ApproveClaimSearch from "./etqa/grants/grantprocessing/ApproveClaimSearch";
import GrantClaimFormReversalSearch from "./etqa/grants/grantprocessing/GrantClaimFormReversalSearch";
import DownloadClaimSearch from "./etqa/grants/grantprocessing/DownloadClaimSearch";
import GenerateBatchSearch from "./etqa/grants/grantprocessing/GenerateBatchSearch";
import DownloadBatchSearch from "./etqa/grants/grantprocessing/DownloadBatchSearch";
import AXProcessorSearch from "./etqa/grants/grantprocessing/AXProcessorSearch";
import GrantReversalsSearch from "./etqa/grants/grantprocessing/GrantReversalsSearch";
import BulkGrantAssociation from "./etqa/grants/grantassociations/BulkGrantAssociation";
import BulkGrantDeAssociation from "./etqa/grants/grantassociations/BulkGrantDiAssociation";

import ProviderCRM from "./etqa/provider/ProviderCRM";
import AssessorIDDocument from "./etqa/assessor/AssessorIDDocument";

import ProviderSearch from "./etqa/provider/ProviderSearch";
import Provider from "./etqa/provider/Provider";
import Outcome from "./etqa/program/Outcome";
import ProviderMonitoringAndEvaluation from "./etqa/provider/ProviderMonitoringAndEvaluation";
import ProviderTrade from "./etqa/provider/ProviderTrade";
import ProviderContact from "./etqa/provider/ProviderContact";
import ProviderUsers from "./etqa/provider/ProviderUsers";
import ProviderSiteVisitsSearch from "./etqa/provider/ProviderSiteVisits";
import ProviderAccreditation from "./etqa/provider/ProviderAccreditation";
import ProviderAccreditationHistory from "./etqa/provider/ProviderAccreditationHistory";
import ProviderLearner from "./etqa/provider/ProviderLearner";
import ProviderUnitStandards from "./etqa/provider/ProviderUnitStandards";
import ProviderQualificationSearch from "./etqa/provider/ProviderQualificationsSearch";
import ProviderSkillsProgrammeSearch from "./etqa/provider/ProviderSkillsProgrammeSearch";
import ProviderSkillsProgrammeUSSearch from "./etqa/provider/ProviderSkillsProgrammeUSSearch";
import ProviderBursarySearch from "./etqa/provider/ProviderBursarySearch";
import ProviderLearnershipUSSearch from "./etqa/provider/ProviderLearnershipUSSearch";
import ProviderQualificationUSSearch from "./etqa/provider/ProviderQualificationUSSearch";
import ProviderQualificationModuleSearch from "./etqa/provider/ProviderQualificationModuleSearch";
import ProviderQualificationModuleSMSearch from "./etqa/provider/ProviderQualificationModuleSMSearch";
import ProviderLearnershipSearch from "./etqa/provider/ProviderLearnershipSearch";
import ProviderAssessorSearch from "./etqa/provider/ProviderAssessors";
import ProviderModeratorSearch from "./etqa/provider/ProviderModeratorSearch";
import ExternalProviderSearch from "./etqa/externalmoderator/ExternalModeratorSearch";
import UnitStandardSetupSearch from "./etqa/program/UnitStandardSetupSearch";
import UnitStandardSetup from "./etqa/program/UnitStandardSetup";
import UnitStandardDocuments from "./etqa/program/UnitStandardDocumentSearch";
import QualificationSetupSearch from "./etqa/program/QualificationSetupSearch";
import QualificationSetup from "./etqa/program/QualificationSetup";
import LearnershipSetupSearch from "./etqa/program/LearnershipSetupSearch";
import AETSetupSearch from "./etqa/program/AET/AETSetupSearch";
import AETSetup from "./etqa/program/AET/AETSetup";
import TradeSetupSearch from "./etqa/program/Trade/TradeSetupSearch";
import TradeSetup from "./etqa/program/Trade/TradeSetup";
import ModuleSetupSearch from "./etqa/program/ModuleSetupSearch";
import ModuleSetup from "./etqa/program/ModuleSetup";
import ModuleDocuments from "./etqa/program/ModuleDocumentSearch";
import SubModuleSetupSearch from "./etqa/program/SubModuleSetupSearch";
import SubModuleSetup from "./etqa/program/SubModuleSetup";
import SubModuleDocuments from "./etqa/program/SubModuleDocumentSearch";
import LearnershipUnitStandardSearch from "./etqa/program/LearnershipUnitStandardSearch";
import LearnershipSetup from "./etqa/program/LearnershipSetup";
import LearnershipSetupCRM from "./etqa/program/LearnershipSetupCRM";
import LearnershipSetupCRMBulkUpload from "./etqa/program/LearnershipSetupCRMBulkUpload";
import SAQAXMLImport from "./etqa/program/SAQAXMLImport";
import ShortCourseSetupSearch from "./etqa/program/ShortCourseSetupSearch";
import SkillsProgramSetupSearch from "./etqa/program/SkillsProgramSetupSearch";
import SkillsProgramSetup from "./etqa/program/SkillsProgramSetup";
import SkillsProgrammeSetupCRM from "./etqa/program/SkillsProgrammeSetupCRM";
import SkillsProgrammeSetupCRMBulkUpload from "./etqa/program/SkillsProgrammeSetupCRMBulkUpload";
import BursarySetupSearch from "./etqa/program/BursarySetupSearch";
import BursarySetup from "./etqa/program/BursarySetup";
import BursarySetupCRM from "./etqa/program/BursarySetupCRM";
import BursarySetupCRMBulkUpload from "./etqa/program/BursarySetupCRMBulkUpload";
import ArtisansSetupSearch from "./etqa/program/ArtisansSetupSearch";
import EmployerLearnerSearch from "./etqa/employer/EmployerLearnerSearch";
import EmployerUserSearch from "./etqa/employer/EmployerUserSearch";
import EmployerContactSearch from "./etqa/employer/EmployerContactSearch";
import EmployerBillingContactSearch from "./etqa/employer/EmployerBillingContactSearch";
import AddLearnerFlow from "./etqa/learner/AddLearnerFlow";
import AddPersonFlow from "./etqa/person/AddPersonFlow";
import AddAssessorFlow from "./etqa/assessor/AddAssessorFlow";
import AddModeratorFlow from "./etqa/moderator/AddModeratorFlow";
import AddFacilitatorFlow from "./etqa/facilitator/AddFacilitatorFlow";
import AddConsultantFlow from "./etqa/consultant/AddConsultantFlow";
import AddProjectOwnerFlow from "./etqa/projectowner/AddProjectOwnerFlow";
import Dashboard from "./widgets/Dashboard";
import SDFDashboard from "./Dashboards/SDFDashboard";
import StakeholderDashboard from "./Dashboards/StakeholderDashboard";
import SkillsDashboard from "./Dashboards/SkillsDashboard";
import { Route } from "react-router-dom";
import QualificationUnitStandardSearch from "./etqa/program/QualificationUnitStandardSearch";
import QualificationModuleSearch from "./etqa/program/QualificationModuleSearch";
import SkillsUnitStandardSearch from "./etqa/program/SkillsUnitStandardSearch";
import BursaryUnitStandardSearch from "./etqa/program/BursaryUnitStandardSearch";
import ModuleSubModuleSearch from "./etqa/program/ModuleSubModuleSearch";
import SDFSearch from "./skills/sdf/SDFSearch";
import RoleMaintenanceSearch from "./maintenance/Role/RoleSearch";
import UserMaintenanceSearch from "./maintenance/User/UserSearch";
import UserDetailsMaintenanceSearch from "./maintenance/User/UserDetail";

import UserRoleSearch from "./maintenance/User/UserRoles";
import ClientsEmailsSearch from "./maintenance/Clients/ClientsEmailsSearch";
import AudienceOptions from "./maintenance/Clients/AudienceOptions";
import EmailAdd from "./maintenance/Clients/EmailAdd";

import SkillsLetterCreationSearch from "./maintenance/Skills/SkillsLetterCreationSearch";
import SkillsLetterCreation from "./maintenance/Skills/SkillsLetterCreation";
import SkillsQueryReasonSearch from "./maintenance/Skills/SkillsQueryReasonSearch";
import SkillsQueryReason from "./maintenance/Skills/SkillsQueryReason";
import SkillsRejectionReasonSearch from "./maintenance/Skills/SkillsRejectionReasonSearch";
import SkillsRejectionReason from "./maintenance/Skills/SkillsRejectionReason";
import SkillsTNumberConversionSearch from "./maintenance/Skills/SkillsTNumberConversionSearch";
import SkillsTNumberConversion from "./maintenance/Skills/SkillsTNumberConversion";

import CreateBenefitsDescriptionSearch from "./maintenance/Benefits/CreateBenefitsDescriptionSearch";
import BenefitsDescriptionsSearch from "./maintenance/Benefits/BenefitsDescriptionsSearch";
import CreateInterventionTitles from "./maintenance/Benefits/CreateInterventionTitles";
import BenefitsTargetAddFormSearch from "./maintenance/Benefits/BenefitsTargetAddFormSearch";
import BenefitsTargetsSearch from "./maintenance/Benefits/BenefitsTargetsSearch";
import InterventionTitlesSearch from "./maintenance/Benefits/InterventionTitlesSearch";

import LinkingSearch from "./maintenance/Benefits/LinkingSearch";
import BenefitsUpload from "./maintenance/Benefits/BenefitsUpload";
import CreateLinkingSearch from "./maintenance/Benefits/CreateLinkingSearch";

import DGLetterCreation from "./maintenance/DG/DGLetterCreation";
import DGLetterCreationSearch from "./maintenance/DG/DGLetterCreationSearch";
import DGQueryReasonSearch from "./maintenance/DG/DGQueryReasonSearch";
import DGQueryReason from "./maintenance/DG/DGQueryReason";
import DGRejectionReasonSearch from "./maintenance/DG/DGRejectionReasonSearch";
import DGRejectionReason from "./maintenance/DG/DGRejectionReason";

import Role from "./maintenance/Role/Role";
import RolePermissionSearch from "./maintenance/Role/RolePermissionSearch";
import RolePermission from "./maintenance/Role/RolePermission";
import SDF from "./skills/sdf/SDF";
import SDFOrganisationDetails from "./skills/sdf/SDFOrganisations";

import DGOrganisationSearch from "./dg/organisation/DGOrganisationSearch";
import DGOrganisation from "./dg/organisation/DGOrganisation";
import StakeholderSearch from "./dg/stakeholder/StakeholderSearch";
import Stakeholder from "./dg/stakeholder/Stakeholder";
import StakeholderOrganisationDetails from "./dg/stakeholder/StakeholderOrganisationDetails";
import DGOrganisationContact from "./dg/organisation/DGOrganisationContact";
import DGDocumentsUpload from "./dg/documentuploads/DGDocumentUploads";
import OrganisationStakeholder from "./dg/organisation/OrganisationStakeholder";
import DGOrganisationCRM from "./dg/organisation/DGOrganisationCRM";

import Organisation from "./skills/organisation/Organisation";
import OrganisationSearch from "./skills/organisation/OrganisationSearch";
import OrganisationContact from "./skills/organisation/OrganisationContact";
import OrganisationBankingDetails from "./skills/organisation/OrganisationBankingDetails";
import ChildOrganisationSearch from "./skills/organisation/ChildOrganisationSearch";
import ChildOrganisation from "./skills/organisation/ChildOrganisation";
import OrganisationCFODetails from "./skills/organisation/OrganisationCFODetails";
import TrainingCommitteeSearchRecord from "./skills/organisation/TrainingCommitteeRecords";
import TrainingCommittee from "./skills/organisation/TrainingCommittee";
import UnionSearchRecord from "./skills/organisation/UnionRecords";

import WspAtrForms from "./skills/WspAtrForms/WspAtrForms";
import EmploymentNumberForm2022 from "./skills/WspAtrForms/Forms/2022/EmploymentNumber";
import EmploymentNumberSmallForm2022 from "./skills/WspAtrForms/Forms/2022/EmploymentNumberSmall";
import AnnualTrainingReportForm2022 from "./skills/WspAtrForms/Forms/2022/AnnualTrainingReport";
import ActualPivotalTrainingForm2022 from "./skills/WspAtrForms/Forms/2022/ActualPivotalTraining";
import WorkplaceSkillsPlanForm2022 from "./skills/WspAtrForms/Forms/2022/WorkplaceSkillsPlan";
import QualificationProfileForm2022 from "./skills/WspAtrForms/Forms/2022/QualificationProfile";
import PlannedPivotalTrainingForm2022 from "./skills/WspAtrForms/Forms/2022/PlannedPivotalTraining";
import ActualBeneficiariesTrainingForm2022 from "./skills/WspAtrForms/Forms/2022/ActualBeneficiariesTraining";
import ActualAETForm2022 from "./skills/WspAtrForms/Forms/2022/ActualAET";
import PlannedBeneficiariesTrainingForm2022 from "./skills/WspAtrForms/Forms/2022/PlannedBeneficiariesTraining";
import ProvincialDetailsForm2022 from "./skills/WspAtrForms/Forms/2022/ProvincialDetails";
import PlannedAETForm2022 from "./skills/WspAtrForms/Forms/2022/PlannedAET";
import ImpactAssessmentForm2022 from "./skills/WspAtrForms/Forms/2022/ImpactAssessment";
import HardToFillVacanciesForm2022 from "./skills/WspAtrForms/Forms/2022/HardToFillVacancies";
import CheckListForm2022 from "./skills/WspAtrForms/Forms/2022/CheckList";
import WspAtrDashboard from "./skills/WspAtrForms/WspAtrDashboard";
import WspAtrDocumentsUpload from "./skills/WspAtrForms/DocumentsUpload";
import WspAtrVerification from "./skills/WspAtrForms/WSPATRVerificationSearch";
import WSPATRVerificationMain from "./skills/WspAtrForms/WSPATRVerificationMain";
import WSPATREvaluation from "./skills/WspAtrForms/WSPATREvaluationSearch";
import WSPATREvaluationMain from "./skills/WspAtrForms/WSPATREvaluationMain";
import Approval from "./skills/WspAtrForms/Approval";
import WspAtrApproval from "./skills/WspAtrForms/WSPATRApprovalSearch";
import WSPATRApprovalMain from "./skills/WspAtrForms/WSPATRApprovalMain";
import Verification from "./skills/WspAtrForms/Verification";
import Evaluation from "./skills/WspAtrForms/Evaluation";
import Feedback from "./skills/organisation/Stackholderfeedback";
import RequestExtension from "./skills/WspAtrForms/Extension";
import LodgeApeal from "./skills/WspAtrForms/LodgeApeal";

import OrganisationSDF from "./skills/organisation/OrganisationSDF";
import OrganisationGrantsLevies from "./skills/organisation/OrganisationGrantsLevies";
import Levies from "./skills/organisation/Levies";
import Grants from "./skills/organisation/Grants";
import DiscretionaryGrants from "./skills/organisation/DiscretionaryGrants";
import InterSetaTransfer from "./skills/organisation/InterSetaTransfer";
import SDFComplianceLetter from "./skills/organisation/OrganisationSDFComplianceLetter";

import EmployerFileImport from "./skills/EmployerFileImport/EmployerFileImport";

import ETQAReportSearch from "./reports/etqa/ETQAReportSearch";
import ETQAReport from "./reports/etqa/ETQAReport";
import SkillsReportSearch from "./reports/skills/SkillsReportSearch";
import SkillsReport from "./reports/skills/SkillsReport";
import MasterFileSearch from "./reports/masterfiles/MasterFileSearch";
import MasterFile from "./reports/masterfiles/MasterFile";
import SETMISFileSearch from "./reports/setmisfiles/SETMISFileSearch";
import SETMISFile from "./reports/setmisfiles/SETMISFile";
import DGReportSearch from "./reports/dg/DGReportSearch";
import DGReport from "./reports/dg/DGReport";
import CustomReports from "./reports/customreports/CustomReports";
import AdminReportSearch from "./reports/admin/AdminReportSearch";
import AdminReport from "./reports/admin/AdminReport";
import ProviderReportSearch from "./reports/provider/ProviderReportSearch";
import ProviderReport from "./reports/provider/ProviderReport";

import ProviderDocuments from "./etqa/approval and certification/ProviderDocuments";
import AssessorDocuments from "./etqa/approval and certification/AssessorDocuments";
import ModeratorDocuments from "./etqa/approval and certification/ModeratorDocuments";
import LearnerDocuments from "./etqa/approval and certification/LearnerDocuments";
import LearnerAttendance from "./etqa/approval and certification/LearnerAttendance";
import BulkApproval from "./etqa/approval and certification/BulkApproval";
import ExpiryNotification from "./etqa/expiry notification/ExpiryNotification";
import LearnerEndorsementLetter from "./etqa/approval and certification/LearnerEndorsementLetter";
import POR from "./etqa/proof of registration/ProofOfRegistrationSearch";

import Test from "./Test";
import TestTableDemo from "./test components/TestTableDemo";

import DGApplication from "./dg/application/DGApplication";
import DGApplicationDashboard from "./dg/application/DGApplicationDashboard";
import DGForms from "./dg/application/Forms/DGForms";
import DGVerificationSearch from "./dg/verification/DGVerificationSearch";
import DGVerificationMain from "./dg/verification/DGVerificationMain";
import DGEvaluationSearch from "./dg/evaluation/DGEvaluationSearch";
import DGEvaluationMain from "./dg/evaluation/DGEvaluationMain";
import DGApprovalSearch from "./dg/approval/DGApprovalSearch";
import DGApprovalMain from "./dg/approval/DGApprovalMain";
import DGContracts from "./dg/contracts/DGContracts";

//DG Forms 2022
import ArtisanForm2022 from "./dg/application/Forms/2022/Artisan";
import AETForm2022 from "./dg/application/Forms/2022/AET";
import BursaryForm2022 from "./dg/application/Forms/2022/Bursary";
import LearnershipForm2022 from "./dg/application/Forms/2022/Learnership";
import CandidacyForm2022 from "./dg/application/Forms/2022/Candidacy";
import TVETForm2022 from "./dg/application/Forms/2022/TVET";
import LearnershipRPLForm2022 from "./dg/application/Forms/2022/LearnershipRPL";
import InternshipForm2022 from "./dg/application/Forms/2022/Internship";
import SkillsProgrammeForm2022 from "./dg/application/Forms/2022/SkillsProgramme";
import WorkExperienceForm2022 from "./dg/application/Forms/2022/WorkExperience";

//Helpdesk
import HelpdeskRequestSearch from "./helpdesk/Request/HelpdeskRequestSearch";
import HelpdeskRequest from "./helpdesk/Request/HelpdeskRequest";
import HelpDeskReport from "./helpdesk/Report/HelpDeskReport";


/*QALP Dashboard*/
import QALPDashboard from "./Dashboards/QALPDashboard";


class Routes extends Component {
    render() {
        return (
            <span>
                <Route path='/dashboard' component={Dashboard} />
                <Route path='/sdfdashboard' component={SDFDashboard} />
                <Route path='/stakeholderdashboard' component={StakeholderDashboard} />
                <Route path='/skillsdashboard' component={SkillsDashboard} />
                <Route path='/employers' component={EmployerSearch} />
                <Route path='/employer' component={Employer} />
                <Route path='/employerLearners' component={EmployerLearnerSearch} />
                <Route path='/employerUsers' component={EmployerUserSearch} />
                <Route path='/employerContacts' component={EmployerContactSearch} />
                <Route path='/employerBillingContacts' component={EmployerBillingContactSearch} />
                <Route path='/workplaceSearch' component={WorkplaceSearch} />
                <Route path='/workplace' component={Workplace} />
                <Route path='/learners' component={LearnerSearch} />
                <Route path='/learner' component={Learner} />
                <Route path='/addLearnerSkillsProgram' component={AddLearnerSkillsProgram} />
                <Route path='/addLearnerBursary' component={AddLearnerBursary} />
                <Route path='/addLearnerInternships' component={AddLearnerInternships} />
                <Route path='/addLearnerLearnership' component={AddLearnerLearnership} />
                <Route path='/addLearnerQualification' component={AddLearnerQualification} />
                <Route path='/addLearnerQCTOQualification' component={AddLearnerQCTOQualification} />
                <Route path='/LearnerQualificationUSSearch' component={LearnerQualificationUSSearch} />
                <Route path='/addLearnerUnitStandard' component={AddLearnerUnitStandard} />
                <Route path='/addLearnerTrade' component={AddLearnerTrade} />
                <Route path='/addLearnerAET' component={AddLearnerAET} />
                <Route path='/addLearnerFET' component={AddLearnerFET} />
                <Route path='/addLearnerCandidacy' component={AddLearnerCandidacy} />
                <Route path='/LearnerSkillsProgrammeSearch' component={LearnerSkillsProgrammeSearch} />
                <Route path='/LearnerBursarySearch' component={LearnerBursarySearch} />
                <Route path='/LearnerAETSearch' component={LearnerAETSearch} />
                <Route path='/LearnerFETSearch' component={LearnerFETSearch} />
                <Route path='/LearnerLearnershipSearch' component={LearnerLearnershipSearch} />
                <Route path='/LearnerTradeSearch' component={LearnerTradeSearch} />
                <Route path='/LearnerInternshipsSearch' component={LearnerInternshipsSearch} />
                <Route path='/LearnerQualificationSearch' component={LearnerQualificationSearch} />
                <Route path='/LearnerQCTOQualificationSearch' component={LearnerQCTOQualificationSearch} />
                <Route path='/LearnerCandidacySearch' component={LearnerCandidacySearch} />
                <Route path='/LearnerUnitStandardSearch' component={LearnerUnitStandardSearch} />
                <Route path='/LearnerLearnershipAchievements' component={LearnerLearnershipAchievements} />
                <Route path='/ViewLearnershipAssessment' component={ViewLearnershipAssessment} />
                <Route path='/ViewSkillsProgrammeAssessment' component={ViewSkillsProgrammeAssessment} />
                <Route path='/ViewBursaryAssessment' component={ViewBursaryAssessment} />
                <Route path='/ViewQualificationAssessment' component={ViewQualificationAssessment} />
                <Route path='/ViewQCTOQualificationAssessment' component={ViewQCTOQualificationAssessment} />
                <Route path='/ViewQCTOQualificationSubModuleAssessment' component={ViewQCTOQualificationSubModuleAssessment} />
                <Route path='/ViewUnitStandardAssessment' component={ViewUnitStandardAssessment} />
                <Route path='/LearnerUnitStandardAssessment' component={LearnerUnitStandardAssessment} />
                <Route path='/LearnerSkillsProgrammeAssessment' component={LearnerSkillsProgrammeAssessment} />
                <Route path='/LearnerBursaryAssessment' component={LearnerBursaryAssessment} />
                <Route path='/LearnerQualificationAssessment' component={LearnerQualificationAssessment} />
                <Route path='/LearnerQCTOQualificationAssessment' component={LearnerQCTOQualificationAssessment} />
                <Route path='/LearnerLearnershipAssessments' component={LearnerLearnershipAssessments} />
                <Route path='/ViewSkillsProgrammeAchievementUS' component={ViewSkillsProgrammeAchievementUS} />
                <Route path='/ViewLearnershipAchievementUS' component={ViewLearnershipAchievementUS} />
                <Route path='/ViewQualificationAchievementUS' component={ViewQualificationAchievementUS} />
                <Route path='/LearnerUnitStandardAchievements' component={LearnerUnitStandardAchievement} />
                <Route path='/LearnerQualificationAchievements' component={LearnerQualificationAchievement} />
                <Route path='/LearnerSkillsProgrammeAchievements' component={LearnerSkillsProgrammeAchievement} />
                <Route path='/learnerCRM' component={LearnerCRM} />
                <Route path='/learnerCRMBulkUpload' component={LearnerCRMBulkUpload} />
                <Route path='/persons' component={PersonSearch} />
                <Route path='/person' component={Person} />

                <Route path='/clientSearch' component={ClientsEmailsSearch} />

                <Route path='/moderatorSearch' component={ModeratorSearch} />
                <Route path='/Moderatorlearnership' component={Moderatorlearnership} />
                <Route path='/ModeratorSkillsProgramme' component={ModeratorSkillsProgramme} />
                <Route path='/ModeratorBursary' component={ModeratorBursary} />
                <Route path='/moderatorQualification' component={ModeratorQualification} />
                <Route path='/moderatorUnitStandard' component={ModeratorUnitStandards} />
                <Route path='/moderatorProviderSearch' component={ModeratorProviderSearch} />
                <Route path='/moderatorCRM' component={ModeratorCRM} />
                <Route path='/uploadPerson' component={UploadPerson} />
                <Route path='/uploadLearnership' component={UploadLearnership} />
                <Route path='/uploadSkills' component={UploadSkills} />
                <Route path='/uploadBursaries' component={UploadBursaries} />
                <Route path='/uploadInternship' component={UploadInternship} />
                <Route path='/uploadAET' component={UploadAET} />
                <Route path='/uploadCandidacy' component={UploadCandidacy} />
                <Route path='/uploadFET' component={UploadFET} />
                <Route path='/uploadQualification' component={UploadQualification} />
                <Route path='/uploadUnitStandard' component={UploadUnitStandard} />
                <Route path='/uploadAchievement' component={UploadAchievement} />
                <Route path='/EmployerCRM' component={EmployerCRM} />

                <Route path='/OrganisationCRM' component={OrganisationCRM} />

                <Route path='/providerCRM' component={ProviderCRM} />

                <Route path='/AssessorSkillsProgrammeSearch' component={AssessorSkillsProgrammeSearch} />
                <Route path='/AssessorBursarySearch' component={AssessorBursarySearch} />
                <Route path='/AssessorQualificationSearch' component={AssessorQualificationSearch} />
                <Route path='/AssessorRegistrationHistory' component={AssessorRegistrationHistory} />
                <Route path='/assessorLearnershipUnitStandards' component={AssessorLearnershipUSSearch} />
                <Route path='/Assessorlearnerships' component={AssessorLearnershipSearch} />
                <Route path='/moderatorRegistrationHistory' component={ModeratorRegistrationHistory} />
                <Route path='/AssessorUnitStandard' component={AssessorUnitStandards} />
                <Route path='/AssessorProviderSearch' component={AssessorProviderSearch} />
                <Route path='/AssessorRegistrationDetails' component={AssessorRegistrationDetails} />
                <Route path='/assessorCRM' component={AssessorCRM} />
                <Route path='/assessorIDDocument' component={AssessorIDDocument} />


                <Route path='/FacilitatorSkillsProgrammeSearch' component={FacilitatorSkillsProgrammeSearch} />
                <Route path='/FacilitatorQualificationSearch' component={FacilitatorQualificationSearch} />
                <Route path='/FacilitatorBursarySearch' component={FacilitatorBursarySearch} />
                <Route path='/FacilitatorRegistrationHistory' component={FacilitatorRegistrationHistory} />
                <Route path='/facilitatorLearnershipUnitStandards' component={FacilitatorLearnershipUSSearch} />
                <Route path='/Facilitatorlearnerships' component={FacilitatorLearnershipSearch} />
                <Route path='/FacilitatorUnitStandard' component={FacilitatorUnitStandards} />
                <Route path='/FacilitatorProviderSearch' component={FacilitatorProviderSearch} />
                <Route path='/FacilitatorRegistrationDetails' component={FacilitatorRegistrationDetails} />
                <Route path='/facilitatorCRM' component={FacilitatorCRM} />

                <Route path='/DGLetterCreationSearch' component={DGLetterCreationSearch} />
                <Route path='/DGLetterCreation' component={DGLetterCreation} />
                <Route path='/DGQueryReasonSearch' component={DGQueryReasonSearch} />
                <Route path='/DGQueryReason' component={DGQueryReason} />
                <Route path='/DGRejectionReasonSearch' component={DGRejectionReasonSearch} />
                <Route path='/DGRejectionReason' component={DGRejectionReason} />

                <Route path='/SkillsLetterCreationSearch' component={SkillsLetterCreationSearch} />
                <Route path='/SkillsLetterCreation' component={SkillsLetterCreation} />
                <Route path='/SkillsQueryReasonSearch' component={SkillsQueryReasonSearch} />
                <Route path='/SkillsQueryReason' component={SkillsQueryReason} />
                <Route path='/SkillsRejectionReasonSearch' component={SkillsRejectionReasonSearch} />
                <Route path='/SkillsRejectionReason' component={SkillsRejectionReason} />
                <Route path='/SkillsTNumberConversionSearch' component={SkillsTNumberConversionSearch} />
                <Route path='/SkillsTNumberConversion' component={SkillsTNumberConversion} />

                <Route path='/maintenanceTargets' component={BenefitsTargetsSearch} />
                <Route path='/CreateInterventionTitles' component={CreateInterventionTitles} />
                <Route path='/maintenanceInterventionTitles' component={InterventionTitlesSearch} />
                <Route path='/maintenanceBenefitDescriptions' component={BenefitsDescriptionsSearch} />
                <Route path='/BenefitsDescriptionSearchForm' component={CreateBenefitsDescriptionSearch} />
                <Route path='/Benefits' component={BenefitsTargetAddFormSearch} />

                <Route path='/maintenanceLinking' component={LinkingSearch} />
                <Route path='/maintenanceBenefitsUploading' component={BenefitsUpload} />

                <Route path='/EmailAdd' component={EmailAdd} />
                <Route path='/maintenanceEmail' component={ClientsEmailsSearch} />
                <Route path='/maintenanceAudienceOptions' component={AudienceOptions} />

                <Route path='/moderator' component={Moderator} />
                <Route path='/moderatorRegistrationDetails' component={ModeratorRegistrationDetails} />


                <Route path='/providerSearch' component={ProviderSearch} />
                <Route path='/provider' component={Provider} />
                <Route path='/outcome' component={Outcome} />
                <Route path="/providerMonitoringAndEvaluation" component={ProviderMonitoringAndEvaluation} />
                <Route path="/providerTrade" component={ProviderTrade} />
                <Route path='/providerContacts' component={ProviderContact} />
                <Route path='/providerSiteVisits' component={ProviderSiteVisitsSearch} />
                <Route path='/providerUsers' component={ProviderUsers} />
                <Route path='/providerAccreditationDetails' component={ProviderAccreditation} />
                <Route path='/providerLearner' component={ProviderLearner} />
                <Route path='/providerUnitStandardSearch' component={ProviderUnitStandards} />
                <Route path='/providerQualificationUSSearch' component={ProviderQualificationUSSearch} />
                <Route path='/providerQualificationModuleSearch' component={ProviderQualificationModuleSearch} />
                <Route path='/providerQualificationModuleSMSearch' component={ProviderQualificationModuleSMSearch} />
                <Route path='/providerQualificationsSearch' component={ProviderQualificationSearch} />
                <Route path='/providerSkillsProgrammeSearch' component={ProviderSkillsProgrammeSearch} />
                <Route path='/providerSkillsProgrammeUSSearch' component={ProviderSkillsProgrammeUSSearch} />
                <Route path='/providerBursarySearch' component={ProviderBursarySearch} />
                <Route path='/providerLearnershipSearch' component={ProviderLearnershipSearch} />
                <Route path='/providerLearnershipUSSearch' component={ProviderLearnershipUSSearch} />
                <Route path='/providerAssessorSearch' component={ProviderAssessorSearch} />
                <Route path='/providerModeratorSearch' component={ProviderModeratorSearch} />
                <Route path="/providerAccreditationHistory" component={ProviderAccreditationHistory} />


                <Route path='/learnerSkillsProgram' component={LearnerSkillsProgram} />

                <Route path='/bda' component={BDASearch} />
                <Route path='/personSearch' component={PersonSearch} />
                <Route path='/externalmoderators' component={ExternalProviderSearch} />
                <Route path='/assessorSearch' component={AssessorSearch} />
                <Route path='/facilitatorSearch' component={FacilitatorSearch} />
                <Route path='/projectownerSearch' component={ProjectOwnerSearch} />
                <Route path='/consultantSearch' component={ConsultantSearch} />
                <Route path='/auditTrial' component={AuditTrial} />
                <Route path='/assessor' component={Assessor} />
                <Route path='/facilitator' component={Facilitator} />
                <Route path='/projectowner' component={ProjectOwner} />
                <Route path='/consultant' component={Consultant} />
                <Route path='/unitstandardsetupSearch' component={UnitStandardSetupSearch} />
                <Route path='/unitStandardSetup' component={UnitStandardSetup} />
                <Route path='/unitstandarddocuments' component={UnitStandardDocuments} />
                <Route path='/qualificationsetupSearch' component={QualificationSetupSearch} />
                <Route path='/qualificationsetup' component={QualificationSetup} />
                <Route path='/qualunitstandardsearch' component={QualificationUnitStandardSearch} />
                <Route path='/qualmodulesearch' component={QualificationModuleSearch} />
                <Route path='/skillsProgramestandardsearch' component={SkillsUnitStandardSearch} />
                <Route path='/bursarystandardsearch' component={BursaryUnitStandardSearch} />
                <Route path='/modulesubmodulesearch' component={ModuleSubModuleSearch} />
                <Route path='/learnershipsetupSearch' component={LearnershipSetupSearch} />
                <Route path='/aetSetupSearch' component={AETSetupSearch} />
                <Route path='/AETsetup' component={AETSetup} />
                <Route path='/tradeSetupSearch' component={TradeSetupSearch} />
                <Route path='/Tradesetup' component={TradeSetup} />
                <Route path='/modulesetupSearch' component={ModuleSetupSearch} />
                <Route path='/moduleSetup' component={ModuleSetup} />
                <Route path='/moduledocuments' component={ModuleDocuments} />
                <Route path='/submodulesetupSearch' component={SubModuleSetupSearch} />
                <Route path='/submoduleSetup' component={SubModuleSetup} />
                <Route path='/submoduledocuments' component={SubModuleDocuments} />
                <Route path='/learnershipUnitStandardSearch' component={LearnershipUnitStandardSearch} />
                <Route path='/learnershipsetup' component={LearnershipSetup} />
                <Route path='/learnershipSetupCRM' component={LearnershipSetupCRM} />
                <Route path='/learnershipSetupCRMBulkUpload' component={LearnershipSetupCRMBulkUpload} />
                <Route path='/saqaxmlimport' component={SAQAXMLImport} />
                <Route path='/shortcoursesetup' component={ShortCourseSetupSearch} />
                <Route path='/skillsProgrammeSetupSearch' component={SkillsProgramSetupSearch} />
                <Route path='/skillsProgrammeSetup' component={SkillsProgramSetup} />
                <Route path='/skillsProgrammeSetupCRM' component={SkillsProgrammeSetupCRM} />
                <Route path='/skillsProgrammeSetupCRMBulkUpload' component={SkillsProgrammeSetupCRMBulkUpload} />
                <Route path='/bursarySetupSearch' component={BursarySetupSearch} />
                <Route path='/bursarySetup' component={BursarySetup} />
                <Route path='/bursarySetupCRM' component={BursarySetupCRM} />
                <Route path='/bursarySetupCRMBulkUpload' component={BursarySetupCRMBulkUpload} />
                <Route path='/artisansetup' component={ArtisansSetupSearch} />
                <Route path='/addLearnerFlow' component={AddLearnerFlow} />
                <Route path='/addPersonFlow' component={AddPersonFlow} />
                <Route path='/addAssessorFlow' component={AddAssessorFlow} />
                <Route path='/addModderatorFlow' component={AddModeratorFlow} />
                <Route path='/addFacilitatorFlow' component={AddFacilitatorFlow} />
                <Route path='/addConsultantFlow' component={AddConsultantFlow} />
                <Route path='/addProjectOwnerFlow' component={AddProjectOwnerFlow} />
                <Route path='/roleMaintenanceSearch' component={RoleMaintenanceSearch} />
                <Route path='/userMaintenanceSearch' component={UserMaintenanceSearch} />

                <Route path='/userRole' component={UserRoleSearch} />


                <Route path='/userDetails' component={UserDetailsMaintenanceSearch} />

                <Route path='/LinkingSearch' component={CreateLinkingSearch} />

                <Route path='/roleMaintenance' component={Role} />
                <Route path='/rolePermissionSearch' component={RolePermissionSearch} />
                <Route path='/rolePermissionMaintenance' component={RolePermission} />
                <Route path='/SDFSearch' component={SDFSearch} />
                <Route path='/sdf' component={SDF} />
                <Route path='/sdfOrgDetails' component={SDFOrganisationDetails} />

                {/*DG Application*/}

                <Route path='/DGOrganisationSearch' component={DGOrganisationSearch} />
                <Route path='/DGOrganisation' component={DGOrganisation} />
                <Route path='/DGApplication' component={DGApplication} />
                <Route path='/DGApplicationDashboard' component={DGApplicationDashboard} />
                <Route path='/DGOrganisationContacts' component={DGOrganisationContact} />
                <Route path='/DGDocumentUploads' component={DGDocumentsUpload} />
                <Route path='/dgForms' component={DGForms} />
                <Route path='/DGVerificationSearch' component={DGVerificationSearch} />
                <Route path='/DGVerificationMain' component={DGVerificationMain} />
                <Route path='/DGEvaluationSearch' component={DGEvaluationSearch} />
                <Route path='/DGEvaluationMain' component={DGEvaluationMain} />
                <Route path='/DGApprovalSearch' component={DGApprovalSearch} />
                <Route path='/DGApprovalMain' component={DGApprovalMain} />
                <Route path="/DGContracts" component={DGContracts} />
                <Route path="/Artisan2022" component={ArtisanForm2022} />
                <Route path="/AET2022" component={AETForm2022} />
                <Route path="/Bursary2022" component={BursaryForm2022} />
                <Route path="/Learnership2022" component={LearnershipForm2022} />
                <Route path="/Candidacy2022" component={CandidacyForm2022} />
                <Route path="/TVET2022" component={TVETForm2022} />
                <Route path="/LearnershipRPL2022" component={LearnershipRPLForm2022} />
                <Route path="/Internship2022" component={InternshipForm2022} />
                <Route path="/SkillsProgramme2022" component={SkillsProgrammeForm2022} />
                <Route path="/WorkExperience2022" component={WorkExperienceForm2022} />

                <Route path='/StakeholderSearch' component={StakeholderSearch} />
                <Route path='/Stakeholder' component={Stakeholder} />
                <Route path='/StakeholderOrganisationDetails' component={StakeholderOrganisationDetails} />
                <Route path='/DGOrganisationStakeholder' component={OrganisationStakeholder} />
                <Route path='/DGOrganisationCRM' component={DGOrganisationCRM} />

                {/*End DG Application*/}

                <Route path='/skillsOrganisationSearch' component={OrganisationSearch} />
                <Route path='/skillsOrganisation' component={Organisation} />
                <Route path='/skillsChildOrganisation' component={ChildOrganisation} />
                <Route path='/skillsOrganisationContacts' component={OrganisationContact} />
                <Route path='/skillsOrganisationBankingDetails' component={OrganisationBankingDetails} />
                <Route path='/skillsChildOrganisationSearch' component={ChildOrganisationSearch} />
                <Route path='/skillsOrganisationCFODetails' component={OrganisationCFODetails} />
                <Route path='/skillsTrainingCommitteeSearch' component={TrainingCommitteeSearchRecord} />
                <Route path='/skillsTrainingCommittee' component={TrainingCommittee} />
                <Route path='/skillsUnion' component={UnionSearchRecord} />
                <Route path='/skillsOrganisationSDF' component={OrganisationSDF} />
                <Route path='/skillsOrganisationLevies' component={OrganisationGrantsLevies} />
                <Route path='/Levies' component={Levies} />
                <Route path='/skillsOrganisationGrants' component={Grants} />
                <Route path='/skillsOrganisationDiscretionaryGrants' component={DiscretionaryGrants} />
                <Route path='/skillsOrganisationInterSetaTransfer' component={InterSetaTransfer} />
                <Route path='/skillsOrganisationLetter' component={SDFComplianceLetter} />

                <Route path='/EmployerFileImport' component={EmployerFileImport} />

                <Route path="/WspAtrForms" component={WspAtrForms} />
                <Route path="/EmploymentNumber2022" component={EmploymentNumberForm2022} />
                <Route path="/EmploymentNumberSmall2022" component={EmploymentNumberSmallForm2022} />
                <Route path="/AnnualTrainingReport2022" component={AnnualTrainingReportForm2022} />
                <Route path="/ActualPivotalTraining2022" component={ActualPivotalTrainingForm2022} />
                <Route path="/WorkplaceSkillsPlan2022" component={WorkplaceSkillsPlanForm2022} />
                <Route path="/QualificationProfile2022" component={QualificationProfileForm2022} />
                <Route path="/PlannedPivotalTraining2022" component={PlannedPivotalTrainingForm2022} />
                <Route path="/ActualBeneficiariesTraining2022" component={ActualBeneficiariesTrainingForm2022} />
                <Route path="/ActualAET2022" component={ActualAETForm2022} />
                <Route path="/PlannedBeneficiariesTraining2022" component={PlannedBeneficiariesTrainingForm2022} />
                <Route path="/ProvincialDetails2022" component={ProvincialDetailsForm2022} />
                <Route path="/PlannedAET2022" component={PlannedAETForm2022} />
                <Route path="/ImpactAssessment2022" component={ImpactAssessmentForm2022} />
                <Route path="/HardToFillVacancies2022" component={HardToFillVacanciesForm2022} />
                <Route path="/CheckList2022" component={CheckListForm2022} />
                <Route path="/wspAtrDashboard" component={WspAtrDashboard} />
                <Route path="/WspAtrDocumentsUpload" component={WspAtrDocumentsUpload} />
                <Route path="/WSPATRVerificationMain" component={WSPATRVerificationMain} />
                <Route path="/WspAtrVerification" component={WspAtrVerification} />
                <Route path="/WSPATREvaluation" component={WSPATREvaluation} />
                <Route path="/WSPATREvaluationMain" component={WSPATREvaluationMain} />
                <Route path="/WSPATRApprovalMain" component={WSPATRApprovalMain} />
                <Route path="/WspAtrApproval" component={WspAtrApproval} />
                <Route path="/Approval" component={Approval} />
                <Route path="/Evaluation" component={Evaluation} />
                <Route path="/Verification" component={Verification} />
                <Route path="/StackhoderFeedback" component={Feedback} />
                <Route path="/WspAtrLodgeApeal" component={LodgeApeal} />
                <Route path="/WspAtrExtensionRequest" component={RequestExtension} />


                <Route path='/ETQAReportSearch' component={ETQAReportSearch} />
                <Route path='/ETQAReport' component={ETQAReport} />
                <Route path='/SkillsReportSearch' component={SkillsReportSearch} />
                <Route path='/SkillsReport' component={SkillsReport} />
                <Route path='/MasterFileSearch' component={MasterFileSearch} />
                <Route path='/MasterFile' component={MasterFile} />
                <Route path='/SETMISFileSearch' component={SETMISFileSearch} />
                <Route path='/SETMISFile' component={SETMISFile} />
                <Route path='/DGReportSearch' component={DGReportSearch} />
                <Route path='/DGReport' component={DGReport} />
                <Route path='/CustomReports' component={CustomReports} />
                <Route path='/AdminReportSearch' component={AdminReportSearch} />
                <Route path='/AdminReport' component={AdminReport} />
                <Route path='/ProviderReportSearch' component={ProviderReportSearch} />
                <Route path='/ProviderReport' component={ProviderReport} />

                <Route path="/providerDocuments" component={ProviderDocuments} />
                <Route path="/assessorDocuments" component={AssessorDocuments} />
                <Route path="/moderatorDocuments" component={ModeratorDocuments} />
                <Route path="/learnerDocuments" component={LearnerDocuments} />
                <Route path="/learnerAttendance" component={LearnerAttendance} />
                <Route path="/bulkApproval" component={BulkApproval} />
                <Route path="/expiryNotification" component={ExpiryNotification} />
                <Route path="/learnerendorsementletter" component={LearnerEndorsementLetter} />
                <Route path="/ProofOfRegistration" component={POR} />

                <Route path='/GrantSetupSearch' component={GrantSetupSearch} />
                <Route path='/AddEditTranche' component={AddEditTranche} />
                <Route path='/TrancheDetails' component={TrancheDetails} />
                <Route path='/TrancheElectiveUS' component={GrantSetupElectiveUSSearch} />
                <Route path='/TrancheProjectCost' component={TrancheSetupProjectCost} />
                <Route path='/TrancheLearners' component={TrancheSetupLearners} />
                <Route path='/ProcessingNav' component={ProcessingNav} />
                <Route path='/GenerateClaim' component={GenerateClaimSearch} />
                <Route path='/ApproveClaim' component={ApproveClaimSearch} />
                <Route path='/GrantClaimFormReversal' component={GrantClaimFormReversalSearch} />
                <Route path='/DownloadClaim' component={DownloadClaimSearch} />
                <Route path='/GenerateBatch' component={GenerateBatchSearch} /> 
                <Route path='/DownloadBatch' component={DownloadBatchSearch} />
                <Route path='/AXProcessor' component={AXProcessorSearch} />
                <Route path='/GrantReversals' component={GrantReversalsSearch} />
                <Route path='/BulkGrantAssociation' component={BulkGrantAssociation} />
                <Route path='/BulkGrantDeAssociation' component={BulkGrantDeAssociation} />

                {/*Helpdesk*/}
                <Route path='/HelpdeskRequestSearch' component={HelpdeskRequestSearch} />
                <Route path='/HelpdeskRequest' component={HelpdeskRequest} />
                <Route path='/HelpDeskReport' component={HelpDeskReport} />
                {/*End Helpdesk*/}


                {/*QALPDashboard*/}
                <Route path='/qalpdashboard' component={QALPDashboard} />

            </span>
        );
    }
}

export default withRouter(connect()(Routes));
