import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();
export const EmployerAddUserForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
<EntityForm ref={ref} viewId="employerAddUser" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
    {form =>
    <form className={form.classes.container} id="employerAddUser">
        <table cellPadding="2" className="w-100">
            <tbody>
                            <tr>

                                <td>
                                    <CustomTextField id="FirstName"
                                        key={tabValue}
                                        label="First Name"
                                        required={true}
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="Surname"
                                        required={true}
                                        key={tabValue}
                                        label="Surname"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                </tr>
                <tr>
                                <td>
                                    <CustomTextField id="IDNo"
                                        required={true}
                                        key={tabValue}
                                        label="ID Number"
                                        validationRegex={/^[0-9]{13}$/g}
                                        validationMessage="The ID Number must be 13 digits long"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <SelectItem
                                        parentId={0}
                                        dataUrl="api/sims/employeruser/GetLookupList?listId=UserType"
                                        id="UserTypeID"
                                        required
                                        key={tabValue}
                                        label="User Type"
                                        className="w-100"
                                        form={form} />
                                </td>
                </tr>
                <tr>
                                <td>
                                    <CustomTextField id="CellphoneNumber"
                                        required={true}
                                        key={tabValue}
                                        label="Cellphone Number"
                                        validationGroup="contactNumbers"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <SelectItem
                                        parentId={0}
                                        dataUrl="api/sims/employeruser/GetLookupList?listId=UserQualification"
                                        id="UserQualificationID"
                                        key={tabValue}
                                        label="User Qualification"
                                        className="w-100"
                                        form={form} />
                    </td>
                </tr>
                <tr>
                                <td>
                                    <CustomTextField id="Email"
                                        key={tabValue}
                                        required={true}
                                        label="EMail"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                </tr>
            </tbody>
        </table>
    </form>
    }
</EntityForm>
    );
})
