import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import GrantSetupSearch from '../../grants/grantsetup/GrantSetupSearch';
import ProviderSearch from '../../provider/ProviderSearch';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../../widgets/Forms';
import PivotalProgrammeSearch from '../../program/PivotalProgrammeSearch';
import LearnershipSetupSearch from '../../program/LearnershipSetupSearch';
import ViewUtils from "../../../ViewUtils";
import { AddressCodeItem } from '../../../widgets/AddressCodeItem';
import { GPSCoordinatesAPI } from '../../../widgets/GPSCoordinatesAPI';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const LearnerLearnershipForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }
    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "SETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    function offsetDate(date, yearOffset, monthOffset, dayOffset) {
        let year = date.getFullYear() + yearOffset;
        let day = date.getDate() + dayOffset;
        let month = date.getMonth() + monthOffset;

        return new Date(year, month, day);
    }

    function StatusCheck(form, status) {
        if (status !== undefined && status !== null) {
            if (status.Description !== "Terminated") {
                let currentDate = new Date();
                let newregend = offsetDate(currentDate, 0, 13, 0);

                form.values['CompletionDate'] = newregend;
            }
            else {
                return false
            }
        }
    }

    const handleReplacementChange = form => event => {
        if (!event.target.checked) {
        }

        form.updateValue('RPL', event.target.checked);
    };

    function SocioCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Employed") {
                return true
            }
            else if (value.Description !== "Employed") {
                return false
            }
        }
    }

    const updateGPSAddress = (address, form) => {
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    useEffect(() => {

    }, [])

    return (
        <EntityForm ref={ref} values={props.data} viewId="LearnershipSetup" dataURL="api/sims/learnerlearnership/GetLearnerLinkedLearnershipDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerLearnershipForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Learnership Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Placement" {...a11yProps(1)} style={{ color: form.fieldParentErrors[1] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td colSpan="1">
                                            <LookupField
                                                id="ProjectID"
                                                label="Project"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required
                                            >
                                                <GrantSetupSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerlearnership/GrantSetupSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ProjectID", value);
                                                        if (props.trancheSetupDetailsHandler) {
                                                            props.trancheSetupDetailsHandler(value);
                                                        }
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="MostRecentRegistrationDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Most Recent Registration Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="LeadProviderID"
                                                label="Lead Provider"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required
                                            >
                                                <ProviderSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerlearnership/ProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("LeadProviderID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="LearnershipID"
                                                label="Learnership"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <LearnershipSetupSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerlearnership/LearnershipSearch"
                                                    onLookupValueChange={(value) => form.setLookupSelection("LearnershipID", value)} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AgreementReferenceNumber"
                                                key={tabValue}
                                                label="Agreement Reference Number"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="LeadEmployerID"
                                                label="Lead Employer"
                                                className="w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                            >
                                                <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerlearnership/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("LeadEmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="SecondaryProviderID"
                                                label="Secondary Provider"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <ProviderSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerlearnership/ProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SecondaryProviderID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="SecondaryEmployerID"
                                                label="Host Employer"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerlearnership/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SecondaryEmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/learnerlearnership/GetLookupList?listId=SETA"
                                                id="SETAID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Quality Assurance Body"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerlearnership/GetLookupList?listId=Sponsorship"
                                                id="SponsorshipID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Sponsorship"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CommencementDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Commencement Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                key={tabValue}
                                                label="Estimate Date of Completion"
                                                className='{classes.textField} w-100 '
                                                required={StatusCheck(form, form.values["ProgrammeStatusID"])}
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="StatusEffectiveDate"
                                                key={tabValue}
                                                label="Status Effective Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerlearnership/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td xs lg="6">
                                            <CustomCheckbox
                                                id="RPL"
                                                key={tabValue}
                                                form={form}
                                                onChange={handleReplacementChange}
                                                disabled={!SocioCheck(form.values["SocioEconomicStatusID"])}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="RPL"
                                            />
                                        </td>
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="DateCertificateCreated"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Date Certificate Created"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Date"
                                                        disabled
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionCapturedDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerlearnership/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="CertificateNumber"
                                                        key={tabValue}
                                                        label="Certificate Number"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerlearnership/GetLookupList?listId=ProgrammeStatus"
                                                        id="ProgrammeStatusID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Programme Status"
                                                        className="{classes.select} w-100"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                        allowEmptyValue={true}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="RegisteredBy"
                                                        key={tabValue}
                                                        label="Registered By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="RegistrationNumber"
                                                        key={tabValue}
                                                        disabled
                                                        label="Registration Number"
                                                        className="w-100 "
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <tr>
                                <td>
                                    <GPSCoordinatesAPI
                                        id="Address"
                                        key={tabValue}
                                        className="w-100"
                                        label="Address"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        form={form}
                                        updateGPSAddress={updateGPSAddress}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress1"
                                        key={tabValue}
                                        label="Employer Physical Address 1"
                                        className="w-100"
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress2"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Employer Physical Address 2"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        parentId={1}
                                        id="PhysicalAddress3"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Employer Physical Address 3"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <AddressCodeItem
                                        id="PhysicalCode"
                                        addressId="Physical"
                                        value={physicalCodeValue}
                                        key={tabValue}
                                        provinceId={!utils.isNull(form.values) ? form.values.PhysicalProvinceID : null}
                                        municipalityId={!utils.isNull(form.values) ? form.values.PhysicalMunicipalityID : null}
                                        districtId={!utils.isNull(form.values) ? form.values.PhysicalDistrictID : null}
                                        urbanRuralId={!utils.isNull(form.values) ? form.values.PhysicalUrbanRuralID : null}
                                        suburbId={!utils.isNull(form.values) ? form.values.PhysicalSuburbID : null}
                                        cityId={!utils.isNull(form.values) ? form.values.PhysicalCityID : null}
                                        className="w-100"
                                        label="Employer Physical Code"
                                        hasMunicipalityID={true}
                                        hasProvinceID={true}
                                        hasCityID={true}
                                        hasDistrictID={false}
                                        hasUrbanRuralID={true}
                                        hasSuburbID={true}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomDatePicker
                                        id="EmploymentStartDate"
                                        key={tabValue}
                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                        label="Employment Start Date"
                                        className='{classes.textField} w-100 '
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
